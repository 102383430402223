.ios .g-login-home, .android .g-login-home {
  width: calc(100vw - 80px);
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: content-box;
  background-image: linear-gradient(to top, #345c9b 0%, #2d4f82 100%); }
  .ios .g-login-home .page-content, .android .g-login-home .page-content {
    overflow: visible;
    padding: 0;
    position: relative;
    height: auto;
    padding-bottom: 40px; }
  .ios .g-login-home .signup-button, .android .g-login-home .signup-button {
    margin-top: 85px !important; }
  .ios .g-login-home .go-offline, .android .g-login-home .go-offline {
    margin-top: 20px; }
  .ios .g-login-home img, .android .g-login-home img {
    display: block;
    width: 119px;
    margin: 40px auto; }
  .ios .g-login-home h2, .ios .g-login-home p, .android .g-login-home h2, .android .g-login-home p {
    text-align: center;
    color: white;
    max-width: 245px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-weight: 300; }
  .ios .g-login-home p, .android .g-login-home p {
    font-weight: 100;
    font-size: 12px; }

.ios .sandbox-label, .android .sandbox-label {
  position: absolute;
  top: 0;
  right: 0; }

.ios .bottom-small, .android .bottom-small {
  font-size: 7px;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; }
