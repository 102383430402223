.g-label {
  color: #e2e2e2;
  font-size: 14px;
  line-height: 15px; }
  .g-label.onClick {
    cursor: pointer; }
  .g-label.pressed {
    filter: brightness(0.8); }
    .g-label.pressed * {
      filter: brightness(0.8); }
  .g-label.x-small {
    font-size: 9px;
    line-height: 9px; }
  .g-label.small {
    font-size: 12px;
    line-height: 12px; }
  .g-label.medium {
    font-size: 14px;
    line-height: 15px; }
  .g-label.large {
    font-size: 20px;
    line-height: 24px; }
  .g-label.dark {
    color: #222; }
  .g-label.dark-gray {
    color: #444; }
  .g-label.white {
    color: white; }
  .g-label.green {
    color: #2BAF45; }
  .g-label.italic {
    font-style: italic; }
  .g-label.justify {
    text-align: justify; }
  .g-label.bold {
    font-weight: 600; }
  .g-label.center {
    text-align: center; }
