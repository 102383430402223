.g-how-to-scan {
  position: relative; }
  .g-how-to-scan .iphone {
    height: 87%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 48px; }
  .g-how-to-scan .content {
    position: relative; }
  .g-how-to-scan .icon-crop-free {
    position: absolute;
    color: white;
    font-size: 100px;
    height: 160px;
    width: 100px;
    top: 160px;
    right: 0px;
    left: 0px;
    margin-left: auto;
    margin-right: auto; }
