.g-progress-bar {
  display: flex;
  border-radius: 14px;
  text-align: center;
  margin-bottom: 12px;
  background-color: #eff0f4;
  box-sizing: border-box;
  font-weight: bold; }
  .g-progress-bar .progress {
    background-color: #4e92df;
    border-radius: 14px;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: width 300ms ease;
    box-sizing: border-box; }
  .g-progress-bar .total {
    border-radius: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: width 300ms ease;
    box-sizing: border-box;
    overflow: hidden;
    color: #222; }
