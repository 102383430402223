#root .popup.g-choose-group-popup {
  height: 290px !important;
  overflow: auto;
  width: 290px !important;
  min-height: 290px !important;
  margin: auto;
  z-index: 13001;
  border-radius: 3px;
  left: 0;
  right: 0;
  top: 130px !important; }

.g-choose-group-popup {
  z-index: 11; }
  .g-choose-group-popup .g-card {
    height: 290px;
    width: 290px;
    padding-left: 0;
    padding-right: 0; }
  .g-choose-group-popup .row {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 15px;
    border-bottom: 1px solid #CFD5DE;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px; }
    .g-choose-group-popup .row > div {
      margin-left: 35px;
      width: 14%;
      text-transform: capitalize; }
  .g-choose-group-popup .g-label.large {
    margin-bottom: 20px;
    margin-left: 30px;
    margin-top: 20px; }
