.g-pull-to-refresh {
  transform: translateY(-87px);
  transition: transform 0.3s ease; }
  .g-pull-to-refresh .card-wrapper {
    perspective: 1000px; }
    .g-pull-to-refresh .card-wrapper > div {
      transform-style: preserve-3d;
      transition: all 0.2s ease-out;
      transform: rotateX(0deg); }
  .g-pull-to-refresh .loading-container {
    display: flex;
    justify-content: center; }
  .g-pull-to-refresh .loading-container .spinning-loading {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #fff;
    border-right-color: #fff;
    animation: spinning-loading 0.5s infinite forwards; }

@keyframes spinning-loading {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
