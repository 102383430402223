.g-content {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  padding-left: calc((100% - 345px) / 2);
  padding-right: calc((100% - 345px) / 2); }

@media only screen and (max-width: 325px) {
  .g-content {
    padding-left: calc((100% - 300px) / 2);
    padding-right: calc((100% - 300px) / 2); } }
