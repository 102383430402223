.g-enter-phone-number-page {
  padding-top: 20px;
  padding-left: calc((100% - 310px) / 2);
  padding-right: calc((100% - 310px) / 2); }
  .g-enter-phone-number-page .g-input {
    margin-top: 20px;
    margin-bottom: 20px; }
  .g-enter-phone-number-page .opt-in {
    display: flex;
    justify-content: flex-start; }
    .g-enter-phone-number-page .opt-in .g-check-box {
      margin-right: 20px;
      margin-bottom: 20px; }
  .g-enter-phone-number-page .g-button:first-of-type {
    margin-top: 40px; }
  .g-enter-phone-number-page .logo-container {
    display: flex;
    margin-top: 40px;
    justify-content: center; }
