.g-scanner-input {
  position: relative;
  box-sizing: border-box;
  height: 50px;
  max-width: 315px;
  margin-bottom: 15px;
  background: white;
  border-radius: 5px;
  padding: 16px;
  color: #222;
  border: 1px solid #CFD5DE; }
  .g-scanner-input .placeholder {
    color: #747474; }
