.g-signature {
  width: 100%;
  color: #CFD5DE; }
  .g-signature canvas {
    border: 1px solid #CFD5DE;
    border-radius: 10px;
    display: block;
    width: 100%;
    height: 100px;
    background: transparent;
    z-index: 2; }
  .g-signature .x {
    border-bottom: 1px solid #CFD5DE;
    position: relative;
    bottom: 55px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    z-index: -1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .g-signature .draw-here {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    bottom: 50px;
    z-index: -1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
