.g-check-box {
  height: 35px;
  width: 35px !important; }
  .g-check-box:not(.checked) .check-box-checked * {
    fill: white;
    stroke: white; }
  .g-check-box:not(.checked) .check-box-checked circle {
    stroke: #CFD5DE; }
  .g-check-box .check-box-checked {
    height: 34px !important;
    width: 34px !important; }

li.g-check-box {
  list-style: none;
  background: white; }
