.g-waste-drug-details-page {
  background: white !important; }
  .g-waste-drug-details-page .page-content {
    padding-top: 44px; }
  .g-waste-drug-details-page .g-input {
    border: 1px solid #CFD5DE; }
  .g-waste-drug-details-page .g-content > div {
    margin-left: auto;
    margin-right: auto; }
  .g-waste-drug-details-page .g-label {
    max-width: 315px;
    margin-bottom: 4px; }
  .g-waste-drug-details-page .g-button {
    margin-top: 60px; }
