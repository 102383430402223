.g-code-authentication-page #CodeCanvas {
  margin: 20px auto; }

.g-code-authentication-page .g-label {
  margin-top: 20px; }

.g-code-authentication-page .g-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
