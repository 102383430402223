#root .g-box-actions-popup.popup {
  background: transparent;
  z-index: 13001; }
  #root .g-box-actions-popup.popup .transparent-backdrop {
    background: transparent;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 13001; }
  #root .g-box-actions-popup.popup .g-label.large {
    color: #222; }
  #root .g-box-actions-popup.popup a.g-button.g-white.bg-darkblue.button {
    background: #2d4f82 !important;
    color: white; }
  #root .g-box-actions-popup.popup a.g-button.g-white.bg-blue.button {
    background: #4e92df !important;
    color: white; }
  #root .g-box-actions-popup.popup a.g-button.g-blue.bg-white.button {
    background: white !important;
    color: #4e92df;
    border: 1px solid #4e92df;
    font-weight: bold; }
  #root .g-box-actions-popup.popup .g-card {
    margin: auto;
    z-index: 130002;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: white !important;
    height: 260px !important;
    width: 260px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    #root .g-box-actions-popup.popup .g-card .g-button {
      width: 200px;
      margin: auto; }
