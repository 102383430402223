.g-how-to-reports .content {
  position: relative; }

.g-how-to-reports .g-content {
  position: absolute;
  bottom: 0px;
  top: 0px;
  margin: auto;
  box-sizing: border-box;
  height: 320px; }

.g-how-to-reports .graph {
  width: 300px;
  display: block;
  margin: auto; }

.g-how-to-reports .icon-lock {
  height: 120px;
  font-size: 120px;
  color: white;
  margin: auto;
  display: block;
  width: 120px; }
