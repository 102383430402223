.g-reset-password-popup {
  z-index: 99999; }
  .g-reset-password-popup .reset-password-popup-page .g-content {
    margin-top: 80px; }
  .g-reset-password-popup .reset-password-popup-page .label {
    margin-bottom: 10px; }
  .g-reset-password-popup .reset-password-popup-page .g-button {
    position: absolute !important;
    bottom: 100px; }
  .g-reset-password-popup .reset-password-popup-page .sendAnother {
    position: absolute !important;
    bottom: 30px;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0; }
  .g-reset-password-popup .reset-password-popup-page .border {
    border: 1px solid #CFD5DE; }
