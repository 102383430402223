.g-app-review-popup.popup {
  background: transparent; }
  .g-app-review-popup.popup .try-again-buttons {
    height: 112px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
  .g-app-review-popup.popup .transparent-backdrop {
    background: transparent;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 13001; }
  .g-app-review-popup.popup .logo {
    height: 70px;
    object-fit: contain; }
  .g-app-review-popup.popup .g-card {
    background: white !important;
    height: 258px !important;
    width: 278px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 13001;
    margin: auto;
    padding: 0 10px; }
  .g-app-review-popup.popup .maybe-later {
    height: 29px;
    padding: 0; }
  .g-app-review-popup.popup .rating {
    display: flex;
    justify-content: space-around;
    height: 27px;
    align-items: center; }
    .g-app-review-popup.popup .rating .g-label {
      font-size: 30px; }
  .g-app-review-popup.popup textarea {
    border: 1px solid #CFD5DE;
    border-radius: 4px;
    height: 80px;
    padding: 3px; }
