.g-invite-page .invites {
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 315px; }
  .g-invite-page .invites .g-input {
    max-width: 315px;
    width: 315px; }

.g-invite-page .g-add-email {
  position: relative; }
  .g-invite-page .g-add-email .icon-close {
    position: absolute;
    right: -32px;
    top: 7px;
    color: white; }

.g-invite-page .g-button {
  margin-top: 30px; }
