.loading .g-inventory-card {
  position: relative;
  overflow: hidden; }
  .loading .g-inventory-card .bottom-row > * {
    background: #eef0f4;
    color: #eef0f4;
    border-radius: 2px;
    height: 16px;
    width: 24px;
    margin-bottom: 4px;
    overflow: hidden; }
  .loading .g-inventory-card .bottom-row strong {
    width: 10px;
    color: #eef0f4; }
  .loading .g-inventory-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%;
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
    animation: shimmer 2s infinite; }

@keyframes shimmer {
  0% {
    left: -150%; }
  100% {
    left: 150%; } }

.g-inventory-card {
  margin-top: 15px;
  background: white;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #CFD5DE;
  padding: 8px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */ }
  .g-inventory-card * {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */ }
  .g-inventory-card.loading {
    position: relative;
    overflow: hidden; }
    .g-inventory-card.loading .bottom-row > * {
      background: #eef0f4;
      color: #eef0f4;
      border-radius: 2px;
      height: 16px;
      width: 24px;
      margin-bottom: 4px;
      overflow: hidden; }
    .g-inventory-card.loading .bottom-row strong {
      width: 10px;
      color: #eef0f4; }
    .g-inventory-card.loading::before {
      content: "";
      position: absolute;
      top: 0;
      left: -150%;
      width: 50%;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
      animation: shimmer 2s infinite; }

@keyframes shimmer {
  0% {
    left: -150%; }
  100% {
    left: 150%; } }
  .g-inventory-card .top-row {
    display: flex;
    justify-content: space-between;
    height: 25px; }
  .g-inventory-card .vials {
    display: flex;
    justify-content: space-between; }
    .g-inventory-card .vials .g-icon {
      width: 17px; }
  .g-inventory-card .next {
    position: relative;
    top: -3px; }
  .g-inventory-card .bottom-row {
    margin-top: 7px;
    display: flex;
    justify-content: flex-start; }
    .g-inventory-card .bottom-row div {
      margin-right: 30px;
      margin-left: 4px; }
    .g-inventory-card .bottom-row strong {
      font-weight: 600; }
