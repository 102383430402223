* {
  letter-spacing: 1px; }

#root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop) * {
  transition: background-color 300ms ease;
  background: transparent;
  border: transparent;
  color: transparent;
  box-shadow: none; }
  #root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop) * img {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0))); }
  #root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop) *:before {
    content: '';
    background: transparent;
    border: transparent;
    color: transparent;
    display: none; }
  #root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop) *:after {
    content: '';
    background: transparent;
    border: transparent;
    color: transparent;
    display: none; }

#root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop) {
  transition: background-color 300ms ease;
  background: transparent;
  border: transparent;
  color: transparent;
  box-shadow: none; }
  #root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop) img {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0))); }
  #root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop):before {
    content: '';
    background: transparent;
    border: transparent;
    color: transparent;
    display: none; }
  #root.transparent #framework7-root > :not(.never-transparent):not(.dialog):not(.dialog-backdrop):after {
    content: '';
    background: transparent;
    border: transparent;
    color: transparent;
    display: none; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #747474; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #747474; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #747474; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #747474; }

body#body.transparent {
  background: transparent;
  border: transparent;
  color: transparent; }
  body#body.transparent ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: transparent; }
  body#body.transparent ::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent; }
  body#body.transparent :-ms-input-placeholder {
    /* IE 10+ */
    color: transparent; }
  body#body.transparent :-moz-placeholder {
    /* Firefox 18- */
    color: transparent; }
  body#body.transparent .g-input input[type=password] {
    background-color: transparent !important; }
  body#body.transparent .modal-in.popup:not(.never-transparent) * {
    background: transparent;
    border: transparent;
    color: transparent;
    box-shadow: none; }
    body#body.transparent .modal-in.popup:not(.never-transparent) * img {
      -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0))); }
    body#body.transparent .modal-in.popup:not(.never-transparent) *:before {
      content: '';
      background: transparent;
      border: transparent;
      color: transparent;
      display: none; }
    body#body.transparent .modal-in.popup:not(.never-transparent) *:after {
      content: '';
      background: transparent;
      border: transparent;
      color: transparent;
      display: none; }

body#body.transparent-incoming-2 {
  background: transparent; }

footer {
  background: transparent;
  margin-bottom: env(safe-area-inset-bottom);
  /* iOS 11.2 */ }

#root .popup {
  transition-duration: 400ms; }

html * {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */ }

html .selectable {
  user-select: text !important;
  /* Enables text selection */
  -webkit-user-select: text !important;
  /* For Safari */
  -ms-user-select: text !important;
  /* For older IE versions */ }

html.ios.with-statusbar .framework7-root {
  padding-top: env(safe-area-inset-top) !important; }

html.ios.device-macos .framework7-root {
  padding-top: env(safe-area-inset-top) !important; }

html.with-statusbar .statusbar {
  height: env(safe-area-inset-top) !important;
  display: block !important;
  background: #2d4f82 !important; }

html.device-macos .statusbar {
  height: env(safe-area-inset-top) !important;
  display: block !important;
  background: #2d4f82 !important; }

html.with-statusbar .panel {
  top: env(safe-area-inset-top) !important;
  height: calc(100vh - env(safe-area-inset-top)) !important;
  height: -webkit-calc(100vh - env(safe-area-inset-top)) !important; }

html.device-macos .panel {
  top: env(safe-area-inset-top) !important;
  height: calc(100vh - env(safe-area-inset-top)) !important;
  height: -webkit-calc(100vh - env(safe-area-inset-top)) !important; }

html.with-statusbar .login-screen {
  height: calc(100vh - env(safe-area-inset-top)) !important;
  height: -webkit-calc(100vh - env(safe-area-inset-top)) !important;
  top: env(safe-area-inset-top) !important; }

html.device-macos .login-screen {
  height: calc(100vh - env(safe-area-inset-top)) !important;
  height: -webkit-calc(100vh - env(safe-area-inset-top)) !important;
  top: env(safe-area-inset-top) !important; }

html.with-statusbar .popup {
  height: calc(100vh - env(safe-area-inset-top)) !important;
  height: -webkit-calc(100vh - env(safe-area-inset-top)) !important;
  top: env(safe-area-inset-top) !important; }

html.device-macos .popup {
  height: calc(100vh - env(safe-area-inset-top)) !important;
  height: -webkit-calc(100vh - env(safe-area-inset-top)) !important;
  top: env(safe-area-inset-top) !important; }

.toast.toast-top.modal-in {
  transform: translate3d(0, 100%, 0) !important; }

.toast.toast-top.modal-out {
  transform: translate3d(0, -100%, 0) !important; }

.ios .toolbar:before {
  background-color: transparent !important; }

@media (min-height: 630px) and (min-width: 630px) {
  div.popup:not(.tablet-fullscreen) {
    height: 100% !important; } }

.ios .toast {
  background: rgba(0, 0, 0, 0.75) !important; }
  .ios .toast .toast-text {
    color: white !important; }

html {
  height: 100vh !important;
  overflow: hidden; }

body {
  height: 100vh !important; }

.redText {
  color: red; }

.views.display-none {
  display: none; }
