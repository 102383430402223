body.icon-font-loading .g-font-icon {
  background-color: transparent !important;
  color: transparent !important; }

.g-font-icon.disabled {
  opacity: 0.5; }

.g-font-icon.blue {
  color: #4e92df; }

.g-font-icon.green {
  color: #2BAF45; }

.g-font-icon.white {
  color: white; }

.g-font-icon.gray {
  color: #e2e2e2; }

.g-font-icon.g-xxxxl {
  font-size: 100px; }

.g-font-icon.g-xxxl {
  font-size: 80px; }

.g-font-icon.g-xxl {
  font-size: 60px; }

.g-font-icon.g-xl {
  font-size: 50px; }

.g-font-icon.g-lg {
  font-size: 40px; }

.g-font-icon.g-md {
  font-size: 30px; }

.g-font-icon.g-sm {
  font-size: 25px; }

.g-font-icon.g-xsm {
  font-size: 18px; }

.g-font-icon.g-xxsm {
  font-size: 10px; }

.g-font-icon.g-disabled {
  cursor: not-allowed; }

@font-face {
  font-family: "untitled-font-1";
  src: url("../../fonts/untitled-font-1.eot");
  src: url("../../fonts/untitled-font-1.eot?#iefix") format("embedded-opentype"), url("../../fonts/untitled-font-1.woff") format("woff"), url("../../fonts/untitled-font-1.ttf") format("truetype"), url("../../fonts/untitled-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "untitled-font-1" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-1" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-access-alarms:before {
  content: "\61"; }

.icon-access-time:before {
  content: "\62"; }

.icon-accessibility:before {
  content: "\63"; }

.icon-account-box:before {
  content: "\64"; }

.icon-account-circle:before {
  content: "\65"; }

.icon-adb:before {
  content: "\66"; }

.icon-add:before {
  content: "\67"; }

.icon-add-alarm:before {
  content: "\68"; }

.icon-add-box:before {
  content: "\69"; }

.icon-add-circle:before {
  content: "\6a"; }

.icon-add-circle-outline:before {
  content: "\6b"; }

.icon-airplanemode-off:before {
  content: "\6c"; }

.icon-airplanemode-on:before {
  content: "\6d"; }

.icon-android:before {
  content: "\6e"; }

.icon-apps:before {
  content: "\6f"; }

.icon-archive:before {
  content: "\70"; }

.icon-arrow-back:before {
  content: "\71"; }

.icon-arrow-drop-down:before {
  content: "\72"; }

.icon-arrow-drop-down-circle:before {
  content: "\73"; }

.icon-arrow-drop-up:before {
  content: "\74"; }

.icon-arrow-forward:before {
  content: "\75"; }

.icon-attachment:before {
  content: "\76"; }

.icon-auto-fix:before {
  content: "\77"; }

.icon-backspace:before {
  content: "\78"; }

.icon-backup:before {
  content: "\79"; }

.icon-beenhere:before {
  content: "\7a"; }

.icon-block:before {
  content: "\41"; }

.icon-bluetooth:before {
  content: "\42"; }

.icon-bluetooth-audio:before {
  content: "\43"; }

.icon-bluetooth-connected:before {
  content: "\44"; }

.icon-bluetooth-disabled:before {
  content: "\45"; }

.icon-bluetooth-searching:before {
  content: "\46"; }

.icon-book:before {
  content: "\47"; }

.icon-bookmark:before {
  content: "\48"; }

.icon-bookmark-outline:before {
  content: "\49"; }

.icon-brightness-auto:before {
  content: "\4a"; }

.icon-brightness-high:before {
  content: "\4b"; }

.icon-brightness-low:before {
  content: "\4c"; }

.icon-brightness-medium:before {
  content: "\4d"; }

.icon-bug-report:before {
  content: "\4e"; }

.icon-cake:before {
  content: "\4f"; }

.icon-call:before {
  content: "\50"; }

.icon-call-end:before {
  content: "\51"; }

.icon-call-made:before {
  content: "\52"; }

.icon-call-merge:before {
  content: "\53"; }

.icon-call-missed:before {
  content: "\54"; }

.icon-call-received:before {
  content: "\55"; }

.icon-call-split:before {
  content: "\56"; }

.icon-camera:before {
  content: "\57"; }

.icon-camera-alt:before {
  content: "\58"; }

.icon-camera-roll:before {
  content: "\59"; }

.icon-cancel:before {
  content: "\5a"; }

.icon-cast:before {
  content: "\30"; }

.icon-cast-connected:before {
  content: "\31"; }

.icon-chat:before {
  content: "\32"; }

.icon-check:before {
  content: "\33"; }

.icon-check-box:before {
  content: "\34"; }

.icon-check-box-blank:before {
  content: "\35"; }

.icon-check-box-outline:before {
  content: "\36"; }

.icon-check-box-outline-blank:before {
  content: "\37"; }

.icon-check-circle:before {
  content: "\38"; }

.icon-check-circle-blank:before {
  content: "\39"; }

.icon-check-circle-outline:before {
  content: "\21"; }

.icon-check-circle-outline-blank:before {
  content: "\22"; }

.icon-chevron-left:before {
  content: "\23"; }

.icon-chevron-right:before {
  content: "\24"; }

.icon-chromecast:before {
  content: "\25"; }

.icon-circles:before {
  content: "\26"; }

.icon-circles-add:before {
  content: "\27"; }

.icon-circles-extended:before {
  content: "\28"; }

.icon-clear:before {
  content: "\29"; }

.icon-close:before {
  content: "\2a"; }

.icon-closed-caption:before {
  content: "\2b"; }

.icon-cloud:before {
  content: "\2c"; }

.icon-cloud-circle:before {
  content: "\2d"; }

.icon-cloud-done:before {
  content: "\2e"; }

.icon-cloud-download:before {
  content: "\2f"; }

.icon-cloud-off:before {
  content: "\3a"; }

.icon-cloud-queue:before {
  content: "\3b"; }

.icon-cloud-upload:before {
  content: "\3c"; }

.icon-comment:before {
  content: "\3d"; }

.icon-communities:before {
  content: "\3e"; }

.icon-content-copy:before {
  content: "\3f"; }

.icon-content-cut:before {
  content: "\40"; }

.icon-content-paste:before {
  content: "\5b"; }

.icon-create:before {
  content: "\5d"; }

.icon-credit-card:before {
  content: "\5e"; }

.icon-crop:before {
  content: "\5f"; }

.icon-crop-free:before {
  content: "\60"; }

.icon-crop-landscape:before {
  content: "\7b"; }

.icon-crop-portrait:before {
  content: "\7c"; }

.icon-crop-square:before {
  content: "\7d"; }

.icon-data-usage:before {
  content: "\7e"; }

.icon-delete:before {
  content: "\5c"; }

.icon-developer-mode:before {
  content: "\e000"; }

.icon-dialpad:before {
  content: "\e001"; }

.icon-directions:before {
  content: "\e002"; }

.icon-directions-bike:before {
  content: "\e003"; }

.icon-directions-bus:before {
  content: "\e004"; }

.icon-directions-car:before {
  content: "\e005"; }

.icon-directions-subway:before {
  content: "\e006"; }

.icon-directions-train:before {
  content: "\e007"; }

.icon-directions-transit:before {
  content: "\e008"; }

.icon-directionswalk:before {
  content: "\e009"; }

.icon-disc-full:before {
  content: "\e00a"; }

.icon-do-not-disturb:before {
  content: "\e00b"; }

.icon-dock:before {
  content: "\e00c"; }

.icon-domain:before {
  content: "\e00d"; }

.icon-done:before {
  content: "\e00e"; }

.icon-done-all:before {
  content: "\e00f"; }

.icon-drafts:before {
  content: "\e010"; }

.icon-drive:before {
  content: "\e011"; }

.icon-drive-document:before {
  content: "\e012"; }

.icon-drive-drawing:before {
  content: "\e013"; }

.icon-drive-eta:before {
  content: "\e014"; }

.icon-drive-file:before {
  content: "\e015"; }

.icon-drive-form:before {
  content: "\e016"; }

.icon-drive-fusiontable:before {
  content: "\e017"; }

.icon-drive-image:before {
  content: "\e018"; }

.icon-drive-ms-excel:before {
  content: "\e019"; }

.icon-drive-ms-powerpoint:before {
  content: "\e01a"; }

.icon-drive-ms-word:before {
  content: "\e01b"; }

.icon-drive-pdf:before {
  content: "\e01c"; }

.icon-drive-presentation:before {
  content: "\e01d"; }

.icon-drive-script:before {
  content: "\e01e"; }

.icon-drive-site:before {
  content: "\e01f"; }

.icon-drive-spreadsheet:before {
  content: "\e020"; }

.icon-drive-video:before {
  content: "\e021"; }

.icon-earth:before {
  content: "\e022"; }

.icon-email:before {
  content: "\e023"; }

.icon-error:before {
  content: "\e024"; }

.icon-event:before {
  content: "\e025"; }

.icon-exit-to-app:before {
  content: "\e026"; }

.icon-expand-less:before {
  content: "\e027"; }

.icon-expand-more:before {
  content: "\e028"; }

.icon-explore:before {
  content: "\e029"; }

.icon-extension:before {
  content: "\e02a"; }

.icon-fast-forward:before {
  content: "\e02b"; }

.icon-fast-rewind:before {
  content: "\e02c"; }

.icon-favorite:before {
  content: "\e02d"; }

.icon-favorite-outline:before {
  content: "\e02e"; }

.icon-file-download:before {
  content: "\e02f"; }

.icon-file-upload:before {
  content: "\e030"; }

.icon-filter:before {
  content: "\e031"; }

.icon-flag:before {
  content: "\e032"; }

.icon-flash-auto:before {
  content: "\e033"; }

.icon-flash-off:before {
  content: "\e034"; }

.icon-flash-on:before {
  content: "\e035"; }

.icon-flights:before {
  content: "\e036"; }

.icon-flip-to-back:before {
  content: "\e037"; }

.icon-flip-to-front:before {
  content: "\e038"; }

.icon-folder:before {
  content: "\e039"; }

.icon-folder-mydrive:before {
  content: "\e03a"; }

.icon-folder-shared:before {
  content: "\e03b"; }

.icon-forum:before {
  content: "\e03c"; }

.icon-forward:before {
  content: "\e03d"; }

.icon-fullscreen:before {
  content: "\e03e"; }

.icon-fullscreen-exit:before {
  content: "\e03f"; }

.icon-gamepad:before {
  content: "\e040"; }

.icon-games:before {
  content: "\e041"; }

.icon-gesture:before {
  content: "\e042"; }

.icon-glass:before {
  content: "\e043"; }

.icon-gmail:before {
  content: "\e044"; }

.icon-google:before {
  content: "\e045"; }

.icon-google-plus:before {
  content: "\e046"; }

.icon-gps-fixed:before {
  content: "\e047"; }

.icon-gps-not-fixed:before {
  content: "\e048"; }

.icon-gps-off:before {
  content: "\e049"; }

.icon-group:before {
  content: "\e04a"; }

.icon-group-add:before {
  content: "\e04b"; }

.icon-hangout:before {
  content: "\e04c"; }

.icon-hangout-video:before {
  content: "\e04d"; }

.icon-hangout-video-off:before {
  content: "\e04e"; }

.icon-headset:before {
  content: "\e04f"; }

.icon-headset-mic:before {
  content: "\e050"; }

.icon-help:before {
  content: "\e051"; }

.icon-high-quality:before {
  content: "\e052"; }

.icon-history:before {
  content: "\e053"; }

.icon-home:before {
  content: "\e054"; }

.icon-hotel:before {
  content: "\e055"; }

.icon-https:before {
  content: "\e056"; }

.icon-image:before {
  content: "\e057"; }

.icon-import-export:before {
  content: "\e058"; }

.icon-inbox:before {
  content: "\e059"; }

.icon-info:before {
  content: "\e05a"; }

.icon-info-outline:before {
  content: "\e05b"; }

.icon-invert-colors:before {
  content: "\e05c"; }

.icon-keep:before {
  content: "\e05d"; }

.icon-keyboard:before {
  content: "\e05e"; }

.icon-keyboard-alt:before {
  content: "\e05f"; }

.icon-keyboard-arrow-down:before {
  content: "\e060"; }

.icon-keyboard-arrow-down-blue:before {
  content: "\e061"; }

.icon-keyboard-arrow-left:before {
  content: "\e062"; }

.icon-keyboard-arrow-right:before {
  content: "\e063"; }

.icon-keyboard-arrow-up:before {
  content: "\e064"; }

.icon-keyboard-backspace:before {
  content: "\e065"; }

.icon-keyboard-capslock:before {
  content: "\e066"; }

.icon-keyboard-control:before {
  content: "\e067"; }

.icon-keyboard-hide:before {
  content: "\e068"; }

.icon-keyboard-return:before {
  content: "\e069"; }

.icon-keyboard-tab:before {
  content: "\e06a"; }

.icon-keyboard-voice:before {
  content: "\e06b"; }

.icon-label:before {
  content: "\e06c"; }

.icon-label-outline:before {
  content: "\e06d"; }

.icon-landscape:before {
  content: "\e06e"; }

.icon-language:before {
  content: "\e06f"; }

.icon-laptop:before {
  content: "\e070"; }

.icon-launch:before {
  content: "\e071"; }

.icon-link:before {
  content: "\e072"; }

.icon-list:before {
  content: "\e073"; }

.icon-live-help:before {
  content: "\e074"; }

.icon-location:before {
  content: "\e075"; }

.icon-location-city:before {
  content: "\e076"; }

.icon-location-disabled:before {
  content: "\e077"; }

.icon-location-searching:before {
  content: "\e078"; }

.icon-lock:before {
  content: "\e079"; }

.icon-lock-open:before {
  content: "\e07a"; }

.icon-lock-outline:before {
  content: "\e07b"; }

.icon-loop:before {
  content: "\e07c"; }

.icon-mail:before {
  content: "\e07d"; }

.icon-map:before {
  content: "\e07e"; }

.icon-markunread:before {
  content: "\e07f"; }

.icon-memory:before {
  content: "\e080"; }

.icon-menu:before {
  content: "\e081"; }

.icon-message:before {
  content: "\e082"; }

.icon-messenger:before {
  content: "\e083"; }

.icon-mic:before {
  content: "\e084"; }

.icon-mic-none:before {
  content: "\e085"; }

.icon-mic-off:before {
  content: "\e086"; }

.icon-mms:before {
  content: "\e087"; }

.icon-mood:before {
  content: "\e088"; }

.icon-more:before {
  content: "\e089"; }

.icon-more-horiz:before {
  content: "\e08a"; }

.icon-more-vert:before {
  content: "\e08b"; }

.icon-mouse:before {
  content: "\e08c"; }

.icon-movie:before {
  content: "\e08d"; }

.icon-nest-protect:before {
  content: "\e08e"; }

.icon-nest-thermostat:before {
  content: "\e08f"; }

.icon-network-cell:before {
  content: "\e090"; }

.icon-network-wifi:before {
  content: "\e091"; }

.icon-news:before {
  content: "\e092"; }

.icon-nfc:before {
  content: "\e093"; }

.icon-notifications:before {
  content: "\e094"; }

.icon-notifications-none:before {
  content: "\e095"; }

.icon-notifications-off:before {
  content: "\e096"; }

.icon-notifications-on:before {
  content: "\e097"; }

.icon-notifications-paused:before {
  content: "\e098"; }

.icon-pages:before {
  content: "\e099"; }

.icon-palette:before {
  content: "\e09a"; }

.icon-panorama:before {
  content: "\e09b"; }

.icon-party-mode:before {
  content: "\e09c"; }

.icon-pause:before {
  content: "\e09d"; }

.icon-pause-circle-fill:before {
  content: "\e09e"; }

.icon-pause-circle-outline:before {
  content: "\e09f"; }

.icon-people:before {
  content: "\e0a0"; }

.icon-person:before {
  content: "\e0a1"; }

.icon-person-add:before {
  content: "\e0a2"; }

.icon-person-location:before {
  content: "\e0a3"; }

.icon-person-outline:before {
  content: "\e0a4"; }

.icon-phone:before {
  content: "\e0a5"; }

.icon-phone-bluetooth-speaker:before {
  content: "\e0a6"; }

.icon-phone-forwarded:before {
  content: "\e0a7"; }

.icon-phone-in-talk:before {
  content: "\e0a8"; }

.icon-phone-locked:before {
  content: "\e0a9"; }

.icon-phone-missed:before {
  content: "\e0aa"; }

.icon-phone-paused:before {
  content: "\e0ab"; }

.icon-photo:before {
  content: "\e0ac"; }

.icon-photo-album:before {
  content: "\e0ad"; }

.icon-photo-library:before {
  content: "\e0ae"; }

.icon-place:before {
  content: "\e0af"; }

.icon-play-arrow:before {
  content: "\e0b0"; }

.icon-play-circle-fill:before {
  content: "\e0b1"; }

.icon-play-circle-outline:before {
  content: "\e0b2"; }

.icon-play-download:before {
  content: "\e0b3"; }

.icon-play-install:before {
  content: "\e0b4"; }

.icon-plus-one:before {
  content: "\e0b5"; }

.icon-poll:before {
  content: "\e0b6"; }

.icon-polymer:before {
  content: "\e0b7"; }

.icon-portrait:before {
  content: "\e0b8"; }

.icon-post-blogger:before {
  content: "\e0b9"; }

.icon-post-facebook:before {
  content: "\e0ba"; }

.icon-post-gplus:before {
  content: "\e0bb"; }

.icon-post-instagram:before {
  content: "\e0bc"; }

.icon-post-linkedin:before {
  content: "\e0bd"; }

.icon-post-pinterest:before {
  content: "\e0be"; }

.icon-post-tumblr:before {
  content: "\e0bf"; }

.icon-post-twitter:before {
  content: "\e0c0"; }

.icon-print:before {
  content: "\e0c1"; }

.icon-public:before {
  content: "\e0c2"; }

.icon-queue:before {
  content: "\e0c3"; }

.icon-radio-button-off:before {
  content: "\e0c4"; }

.icon-radio-button-on:before {
  content: "\e0c5"; }

.icon-receipt:before {
  content: "\e0c6"; }

.icon-refresh:before {
  content: "\e0c7"; }

.icon-reminder:before {
  content: "\e0c8"; }

.icon-remove:before {
  content: "\e0c9"; }

.icon-remove-circle:before {
  content: "\e0ca"; }

.icon-remove-circle-outline:before {
  content: "\e0cb"; }

.icon-replay:before {
  content: "\e0cc"; }

.icon-reply:before {
  content: "\e0cd"; }

.icon-reply-all:before {
  content: "\e0ce"; }

.icon-report:before {
  content: "\e0cf"; }

.icon-ring-volume:before {
  content: "\e0d0"; }

.icon-rotate-left:before {
  content: "\e0d1"; }

.icon-rotate-right:before {
  content: "\e0d2"; }

.icon-satellite:before {
  content: "\e0d3"; }

.icon-save:before {
  content: "\e0d4"; }

.icon-schedule:before {
  content: "\e0d5"; }

.icon-school:before {
  content: "\e0d6"; }

.icon-screen-lock-landscape:before {
  content: "\e0d7"; }

.icon-screen-lock-portrait:before {
  content: "\e0d8"; }

.icon-screen-lock-rotation:before {
  content: "\e0d9"; }

.icon-screen-rotation:before {
  content: "\e0da"; }

.icon-sd-card:before {
  content: "\e0db"; }

.icon-sd-storage:before {
  content: "\e0dc"; }

.icon-search:before {
  content: "\e0dd"; }

.icon-select-all:before {
  content: "\e0de"; }

.icon-send:before {
  content: "\e0df"; }

.icon-settings:before {
  content: "\e0e0"; }

.icon-settings-applications:before {
  content: "\e0e1"; }

.icon-settings-bluetooth:before {
  content: "\e0e2"; }

.icon-settings-cell:before {
  content: "\e0e3"; }

.icon-settings-phone:before {
  content: "\e0e4"; }

.icon-settings-power:before {
  content: "\e0e5"; }

.icon-settings-voice:before {
  content: "\e0e6"; }

.icon-share:before {
  content: "\e0e7"; }

.icon-share-alt:before {
  content: "\e0e8"; }

.icon-shopping-basket:before {
  content: "\e0e9"; }

.icon-shopping-cart:before {
  content: "\e0ea"; }

.icon-shuffle:before {
  content: "\e0eb"; }

.icon-signal-cellular-1-bar:before {
  content: "\e0ec"; }

.icon-signal-cellular-2-bar:before {
  content: "\e0ed"; }

.icon-signal-cellular-3-bar:before {
  content: "\e0ee"; }

.icon-signal-cellular-4-bar:before {
  content: "\e0ef"; }

.icon-signal-wifi-1-bar:before {
  content: "\e0f0"; }

.icon-signal-wifi-2-bar:before {
  content: "\e0f1"; }

.icon-signal-wifi-3-bar:before {
  content: "\e0f2"; }

.icon-signal-wifi-4-bar:before {
  content: "\e0f3"; }

.icon-sim-card-alert:before {
  content: "\e0f4"; }

.icon-skip-next:before {
  content: "\e0f5"; }

.icon-skip-previous:before {
  content: "\e0f6"; }

.icon-slideshow:before {
  content: "\e0f7"; }

.icon-sms:before {
  content: "\e0f8"; }

.icon-sms-failed:before {
  content: "\e0f9"; }

.icon-sort:before {
  content: "\e0fa"; }

.icon-speaker:before {
  content: "\e0fb"; }

.icon-star:before {
  content: "\e0fc"; }

.icon-star-half:before {
  content: "\e0fd"; }

.icon-star-outline:before {
  content: "\e0fe"; }

.icon-star-rate:before {
  content: "\e0ff"; }

.icon-stop:before {
  content: "\e100"; }

.icon-storage:before {
  content: "\e101"; }

.icon-store:before {
  content: "\e102"; }

.icon-swap-driving-apps:before {
  content: "\e103"; }

.icon-swap-horiz:before {
  content: "\e104"; }

.icon-swap-vert:before {
  content: "\e105"; }

.icon-swap-vert-circle:before {
  content: "\e106"; }

.icon-switch-camera:before {
  content: "\e107"; }

.icon-switch-video:before {
  content: "\e108"; }

.icon-sync:before {
  content: "\e109"; }

.icon-sync-disabled:before {
  content: "\e10a"; }

.icon-sync-green:before {
  content: "\e10b"; }

.icon-sync-problem:before {
  content: "\e10c"; }

.icon-sync-problem-red:before {
  content: "\e10d"; }

.icon-system-update:before {
  content: "\e10e"; }

.icon-tab:before {
  content: "\e10f"; }

.icon-tab-unselected:before {
  content: "\e110"; }

.icon-tablet:before {
  content: "\e111"; }

.icon-tag-faces:before {
  content: "\e112"; }

.icon-tap-and-play:before {
  content: "\e113"; }

.icon-terrain:before {
  content: "\e114"; }

.icon-text-format:before {
  content: "\e115"; }

.icon-textsms:before {
  content: "\e116"; }

.icon-theaters:before {
  content: "\e117"; }

.icon-thumb-down:before {
  content: "\e118"; }

.icon-thumb-up:before {
  content: "\e119"; }

.icon-time-to-leave:before {
  content: "\e11a"; }

.icon-timelapse:before {
  content: "\e11b"; }

.icon-timer:before {
  content: "\e11c"; }

.icon-today:before {
  content: "\e11d"; }

.icon-traffic:before {
  content: "\e11e"; }

.icon-translate:before {
  content: "\e11f"; }

.icon-tv:before {
  content: "\e120"; }

.icon-undo:before {
  content: "\e121"; }

.icon-unfold-less:before {
  content: "\e122"; }

.icon-unfold-more:before {
  content: "\e123"; }

.icon-unknown-1:before {
  content: "\e124"; }

.icon-unknown-2:before {
  content: "\e125"; }

.icon-unknown-3:before {
  content: "\e126"; }

.icon-unknown-4:before {
  content: "\e127"; }

.icon-unknown-5:before {
  content: "\e128"; }

.icon-unknown-6:before {
  content: "\e129"; }

.icon-unknown-7:before {
  content: "\e12a"; }

.icon-usb:before {
  content: "\e12b"; }

.icon-vibration:before {
  content: "\e12c"; }

.icon-video-youtube:before {
  content: "\e12d"; }

.icon-videocam:before {
  content: "\e12e"; }

.icon-videocam-off:before {
  content: "\e12f"; }

.icon-view-array:before {
  content: "\e130"; }

.icon-view-column:before {
  content: "\e131"; }

.icon-view-headline:before {
  content: "\e132"; }

.icon-view-list:before {
  content: "\e133"; }

.icon-view-module:before {
  content: "\e134"; }

.icon-view-quilt:before {
  content: "\e135"; }

.icon-view-stream:before {
  content: "\e136"; }

.icon-visibility:before {
  content: "\e137"; }

.icon-visibility-off:before {
  content: "\e138"; }

.icon-voice:before {
  content: "\e139"; }

.icon-voicemail:before {
  content: "\e13a"; }

.icon-volume-down:before {
  content: "\e13b"; }

.icon-volume-mute:before {
  content: "\e13c"; }

.icon-volume-off:before {
  content: "\e13d"; }

.icon-volume-up:before {
  content: "\e13e"; }

.icon-vpn:before {
  content: "\e13f"; }

.icon-warning:before {
  content: "\e140"; }

.icon-watch:before {
  content: "\e141"; }

.icon-wb-auto:before {
  content: "\e142"; }

.icon-wb-cloudy:before {
  content: "\e143"; }

.icon-wb-incandescent:before {
  content: "\e144"; }

.icon-wb-irradescent:before {
  content: "\e145"; }

.icon-wb-sunny:before {
  content: "\e146"; }

.icon-web:before {
  content: "\e147"; }

.icon-whatshot:before {
  content: "\e148"; }

.icon-wifi-tethering:before {
  content: "\e149"; }

.icon-work:before {
  content: "\e14a"; }
