#root .g-ndc-scanner-explainer-popup {
  z-index: 13001; }
  #root .g-ndc-scanner-explainer-popup .page-content {
    background: white !important; }
    #root .g-ndc-scanner-explainer-popup .page-content .g-label {
      margin: 20px; }
    #root .g-ndc-scanner-explainer-popup .page-content img {
      display: flex;
      position: relative;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto; }
  #root .g-ndc-scanner-explainer-popup .g-button {
    position: absolute !important;
    bottom: 30px; }
