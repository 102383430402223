.ios .g-how-to-view {
  background-image: linear-gradient(to top, #345c9b 0%, #2d4f82 100%); }
  .ios .g-how-to-view h2 {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    max-width: 300px;
    margin-top: 0;
    margin-bottom: 28px; }
  .ios .g-how-to-view .container {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: 100%; }
  .ios .g-how-to-view .content {
    height: calc(100% - 200px); }
  .ios .g-how-to-view .g-button, .ios .g-how-to-view h2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .ios .g-how-to-view .g-button {
    position: absolute;
    bottom: 65px;
    left: 0;
    right: 0;
    margin: auto; }
  .ios .g-how-to-view .dots {
    display: flex;
    width: 130px;
    justify-content: space-between;
    position: absolute;
    bottom: 25px;
    right: 0;
    left: 0;
    margin: auto; }
  .ios .g-how-to-view .open-dot {
    background: transparent;
    border: 1px solid white;
    height: 15px;
    width: 15px;
    border-radius: 8px; }
  .ios .g-how-to-view .closed-dot {
    background: white;
    border: 1px solid white;
    height: 15px;
    width: 15px;
    border-radius: 8px; }
  .ios .g-how-to-view .white-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 35px; }
