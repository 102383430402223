.g-forgot-password-page .g-content {
  margin-top: 80px; }

.g-forgot-password-page .label {
  margin-bottom: 10px; }

.g-forgot-password-page .g-button {
  position: absolute !important;
  bottom: 30px; }

.g-forgot-password-page .border {
  border: 1px solid #CFD5DE; }
