.g-enter-dual-factor-code-popup {
  z-index: 13001; }
  .g-enter-dual-factor-code-popup .icon-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 13001; }
  .g-enter-dual-factor-code-popup .g-card {
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px; }
    .g-enter-dual-factor-code-popup .g-card > * > * {
      width: 100%;
      text-align: center;
      left: 0;
      right: 0;
      margin: auto; }
  .g-enter-dual-factor-code-popup .dual-factor-code-page {
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px; }
    .g-enter-dual-factor-code-popup .dual-factor-code-page .g-input {
      margin-top: 20px;
      margin-bottom: 20px; }
    .g-enter-dual-factor-code-popup .dual-factor-code-page .expiration {
      margin-top: 10px; }
