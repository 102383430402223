.g-verify-page {
  background: #2d4f82; }
  .g-verify-page .page-content {
    padding-top: 60px;
    background-image: none;
    background: #2d4f82; }
  .g-verify-page .g-content > * {
    margin-bottom: 20px; }
  .g-verify-page .g-drop-down, .g-verify-page .g-input {
    margin-left: auto;
    margin-right: auto; }
  .g-verify-page .g-input input {
    border: 1px solid #CFD5DE; }
  .g-verify-page .g-drop-down {
    width: 100%; }
  .g-verify-page div.g-label.large.white {
    color: white;
    margin-left: auto;
    margin-right: auto;
    width: 315px; }
  .g-verify-page .g-icon.biometric {
    margin: 35px auto; }
  .g-verify-page .divider {
    height: 1px;
    background: white;
    width: 315px;
    position: relative;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px; }
