.g-box-list-page {
  position: relative; }
  .g-box-list-page .info {
    margin-top: 80px;
    margin-bottom: 10px; }
  .g-box-list-page.page {
    background: #eff0f4; }
  .g-box-list-page .page-content {
    position: relative; }
  .g-box-list-page .g-button {
    position: absolute !important;
    bottom: 30px; }
  .g-box-list-page .g-drop-down {
    width: 100%;
    max-width: 100%; }
