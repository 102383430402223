.g-drop-down {
  position: relative;
  box-sizing: border-box;
  height: 50px;
  max-width: 315px;
  margin-bottom: 15px;
  margin-top: 0px;
  background: white;
  border-radius: 4px;
  color: #222;
  width: 200px;
  overflow: hidden; }
  .g-drop-down * {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */ }
  .g-drop-down .g-font-icon.icon-chevron-right.g-sm:before {
    transition: transform .3s ease;
    display: block; }
  .g-drop-down .disabled {
    opacity: .5; }
  .g-drop-down .selected {
    background: #00000006;
    font-weight: 600; }
  .g-drop-down .g-input {
    margin-bottom: 0px;
    border-radius: 4px 4px 0 0;
    height: 100%; }
  .g-drop-down .closed-text {
    margin: auto;
    top: 0px;
    bottom: 0px;
    position: absolute;
    height: 19px;
    margin-left: 18px;
    box-sizing: border-box;
    width: calc(100% - 36px);
    text-align: left; }
  .g-drop-down .options {
    background: white;
    padding: 0px;
    text-align: center;
    border-radius: 5px;
    max-height: 0px;
    overflow: hidden;
    border-radius: 0 0 4px 4px !important;
    -webkit-overflow-scrolling: touch;
    font-weight: 400;
    transition: max-height .5s ease; }
    .g-drop-down .options > div {
      padding: 10px; }
      .g-drop-down .options > div:first-child {
        border-bottom: 1px solid #e2e2e2; }
  .g-drop-down.open {
    overflow: visible;
    z-index: 1;
    border-radius: 4px 4px 0 0 !important; }
    .g-drop-down.open .g-font-icon.icon-chevron-right.g-sm:before {
      transform: rotate(180deg) !important; }
    .g-drop-down.open .options {
      max-height: 240px;
      overflow-y: scroll;
      padding: 20px;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px; }
  .g-drop-down.center {
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }
  .g-drop-down select {
    text-transform: capitalize;
    background: transparent;
    box-sizing: border-box;
    padding-left: 17px;
    padding-right: 40px;
    font-size: 14px;
    border: 0px;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1; }
    .g-drop-down select.icon {
      padding-left: 40px; }
  .g-drop-down .g-font-icon.icon-chevron-right {
    position: absolute;
    right: 8px;
    top: 14px;
    transform: rotate(90deg);
    color: #4e92df;
    background: #ffffffb0;
    backdrop-filter: blur(1px); }
  .g-drop-down .g-font-icon.on-left {
    position: absolute;
    top: 14px;
    left: 13px;
    font-size: 20px; }
