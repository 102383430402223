#root .g-guest-verify-page {
  background: #2d4f82; }
  #root .g-guest-verify-page .g-content > * {
    width: 300px;
    margin: auto;
    margin-bottom: 15px; }
  #root .g-guest-verify-page .g-button {
    margin-top: 40px !important; }
  #root .g-guest-verify-page .signature-box {
    height: 75px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    overflow: hidden; }
    #root .g-guest-verify-page .signature-box img {
      width: 100%;
      height: 100%; }
