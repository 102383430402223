#root.transparent-incoming .g-dash-board-page {
  background: #FAFAFD; }
  #root.transparent-incoming .g-dash-board-page .page-content {
    top: -100vh !important; }

#root.transparent-incoming .g-dash-board-page-nav-bar-inner {
  left: -50%;
  transition: left 200ms ease, background-color 200ms ease !important; }
  #root.transparent-incoming .g-dash-board-page-nav-bar-inner * {
    background-color: transparent !important;
    color: transparent !important;
    transition: color 200ms ease, background-color 200ms ease !important; }

body.main-font-loading .g-dash-board-page .page-content {
  top: -100vh; }

.g-dash-board-page * {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.g-dash-board-page .box-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px; }

.g-dash-board-page .copy-button {
  fill: #4e92df;
  margin-left: 4px; }

.g-dash-board-page .box-barcode-label {
  font-weight: bold;
  margin-right: 4px; }

.g-dash-board-page .sandbox-label {
  position: absolute;
  top: 66px;
  right: 0;
  color: #222; }

.g-dash-board-page .page-content {
  transition: top 200ms ease;
  top: 0;
  position: relative;
  padding-bottom: 40px; }

.g-dash-board-page .interval-row {
  background: #eff0f4;
  border-bottom: 1px solid #CFD5DE;
  display: flex;
  justify-content: space-between; }
  .g-dash-board-page .interval-row .g-drop-down {
    margin-top: 7px;
    margin-bottom: 5px; }

.g-dash-board-page .empty-box {
  margin-top: 20px; }

.g-dash-board-page .stat-row {
  display: flex;
  justify-content: space-around;
  text-align: center;
  background: white;
  border-bottom: 1px solid #CFD5DE;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  position: relative;
  z-index: -1; }
  .g-dash-board-page .stat-row > div {
    display: flex;
    flex-direction: column; }
    .g-dash-board-page .stat-row > div div:nth-of-type(2n - 1) {
      font-weight: bold; }
    .g-dash-board-page .stat-row > div div:nth-of-type(2n) {
      font-size: 12px;
      font-weight: 100; }

.g-dash-board-page .current-inventory-row {
  padding-bottom: 30px; }
  .g-dash-board-page .current-inventory-row .last-check {
    display: flex;
    height: 20px;
    font-size: 12px; }
    .g-dash-board-page .current-inventory-row .last-check .g-font-icon {
      position: relative;
      top: -7px; }

.g-drop-down.g-navbar-box-select {
  height: 34px;
  margin: auto 0;
  width: calc(100vw - 84px); }
  .g-drop-down.g-navbar-box-select .closed-text {
    text-align: center !important; }
  .g-drop-down.g-navbar-box-select .g-font-icon.icon-chevron-right {
    top: 5px; }

@media only screen and (max-width: 325px) {
  .g-dash-board-page h2 {
    font-size: 17px; } }
