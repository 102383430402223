.g-select-unit-popup {
  z-index: 13001;
  background-color: #FAFAFD; }
  .g-select-unit-popup .select-unit-popup-page .icon-close {
    position: absolute;
    right: 20px;
    top: 20px; }
  .g-select-unit-popup .select-unit-popup-page .g-button {
    margin: auto; }
  .g-select-unit-popup .select-unit-popup-page .g-drop-down {
    margin: auto;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 40px; }
