#root.transparent-incoming .g-scan-toolbar {
  bottom: -100px !important; }

.g-scan-toolbar {
  bottom: 10px;
  transition: bottom 200ms ease;
  height: calc(44px + env(safe-area-inset-bottom)) !important; }
  .g-scan-toolbar * {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; }
  .g-scan-toolbar .toolbar-inner {
    height: calc(44px + env(safe-area-inset-bottom)) !important;
    display: flex;
    padding: 0 !important;
    position: relative;
    background-color: #2d4f82; }
    .g-scan-toolbar .toolbar-inner .g-button {
      height: 100%;
      width: 50%;
      border-radius: 0px;
      box-shadow: none;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 0;
      padding-right: 0;
      max-width: 100%; }
    .g-scan-toolbar .toolbar-inner .divider {
      width: 1px;
      height: 50%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: white;
      z-index: 1; }
