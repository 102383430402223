input.f7-dialog {
  width: 100%;
  height: 26px;
  margin-bottom: 5px;
  padding: 10px;
  color: #222;
  border: 1px solid #CFD5DE; }
  input.f7-dialog.no-border {
    border: none; }

.g-box-scanned-popup {
  z-index: 13001; }
  .g-box-scanned-popup .page {
    background: white; }
  .g-box-scanned-popup .g-image-input {
    width: 100%; }
    .g-box-scanned-popup .g-image-input label {
      margin: auto; }
  .g-box-scanned-popup .success.hide {
    height: 0 !important;
    margin: 0 !important; }
  .g-box-scanned-popup .success {
    overflow: hidden;
    transition: height 300ms ease, margin 300ms ease; }
  .g-box-scanned-popup .box-scanned-popup-page input {
    border: 1px solid #CFD5DE; }
  .g-box-scanned-popup .box-scanned-popup-page .page-content {
    padding-top: 0px !important;
    padding-bottom: 70px !important; }
    .g-box-scanned-popup .box-scanned-popup-page .page-content::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */ }
  .g-box-scanned-popup .box-scanned-popup-page .block {
    margin-bottom: 0px !important;
    margin-top: 15px !important; }
  .g-box-scanned-popup .box-scanned-popup-page .content-block {
    margin-top: 0px;
    padding-bottom: 70px; }
  .g-box-scanned-popup .box-scanned-popup-page .info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; }
  .g-box-scanned-popup .box-scanned-popup-page .success {
    color: #2BAF45;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-top: 10px;
    height: 25px;
    margin-bottom: 5px; }
    .g-box-scanned-popup .box-scanned-popup-page .success .g-font-icon.icon-done-all.g-sm {
      position: relative;
      top: -8px; }
    .g-box-scanned-popup .box-scanned-popup-page .success div {
      min-width: 25px;
      height: 25px; }
    .g-box-scanned-popup .box-scanned-popup-page .success p {
      margin: 0; }
  .g-box-scanned-popup .box-scanned-popup-page img {
    display: block;
    margin: auto;
    margin-bottom: 30px; }
  .g-box-scanned-popup .box-scanned-popup-page .g-button {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .g-box-scanned-popup .box-scanned-popup-page .top-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; }
    .g-box-scanned-popup .box-scanned-popup-page .top-row .dark {
      text-transform: uppercase; }
  .g-box-scanned-popup .box-scanned-popup-page .drug-row {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 5px;
    padding-top: 5px; }
    .g-box-scanned-popup .box-scanned-popup-page .drug-row:last-child {
      border-bottom: none !important; }
    .g-box-scanned-popup .box-scanned-popup-page .drug-row.ninety .expiration {
      color: #ff6519; }
    .g-box-scanned-popup .box-scanned-popup-page .drug-row.sixty .expiration {
      color: #ff0000; }
    .g-box-scanned-popup .box-scanned-popup-page .drug-row .g-check-box {
      transition: opacity 1.5s ease; }
  .g-box-scanned-popup .box-scanned-popup-page .card-list {
    margin-bottom: 5px;
    padding-bottom: 5px; }
  .g-box-scanned-popup .box-scanned-popup-page .drug-row > div {
    width: 60px;
    font-size: 12px; }
    .g-box-scanned-popup .box-scanned-popup-page .drug-row > div:nth-of-type(2) {
      margin-left: 10px  !important; }
    .g-box-scanned-popup .box-scanned-popup-page .drug-row > div:nth-of-type(3) {
      width: 130px;
      text-align: right;
      margin-right: 0 !important; }
    .g-box-scanned-popup .box-scanned-popup-page .drug-row > div:not(:first-of-type) {
      margin: auto; }
  .g-box-scanned-popup .box-scanned-popup-page .g-card {
    margin-top: 15px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px; }
  .g-box-scanned-popup .box-scanned-popup-page .dark {
    color: #222;
    font-weight: 600; }
  .g-box-scanned-popup .box-scanned-popup-page .g-scanner-input {
    border: 1px solid #CFD5DE;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto; }
  .g-box-scanned-popup .g-navbar {
    background: #2d4f82;
    display: flex;
    color: white;
    justify-content: space-between;
    height: 46px;
    padding: 7px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px; }
    .g-box-scanned-popup .g-navbar div {
      margin: auto 0;
      min-width: 25px;
      min-height: 1px; }

.g-box-scan-toolbar {
  bottom: 10px;
  height: calc(44px + env(safe-area-inset-bottom)) !important; }
  .g-box-scan-toolbar .toolbar-inner {
    height: calc(44px + env(safe-area-inset-bottom)) !important;
    display: flex;
    padding: 0 !important;
    position: relative;
    background-color: #2d4f82; }
    .g-box-scan-toolbar .toolbar-inner .g-button {
      width: 300px;
      height: 100%;
      width: 100%;
      min-width: 100%;
      border-radius: 0; }
