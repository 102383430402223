.g-scanner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0; }
  .g-scanner .navbar-inner {
    font-size: 14px;
    position: relative;
    transition: left 200ms ease; }
    .g-scanner .navbar-inner * {
      transition: color 200ms ease, background-color 200ms ease, filter 200ms ease !important; }
  .g-scanner .animate-from-top {
    transition: top 200ms ease;
    position: relative;
    height: 100%;
    width: 100%; }
  .g-scanner .g-input input::placeholder {
    color: #747474 !important;
    text-align: center; }
  .g-scanner .page-content {
    transition: background-color 200ms ease;
    background: transparent;
    height: 32%;
    width: 100%; }
  .g-scanner .data-matrix {
    height: 10px !important;
    width: 10px !important;
    margin-right: 5px; }

#scanner {
  display: none; }
  #scanner .wide .scan-box {
    width: 200px; }
  #scanner .wide .scan-bar {
    width: 200px; }
  #scanner .wide .gray-box.side {
    width: calc((100vw - 200px) / 2); }
  #scanner .scanner-page.page {
    transition: background-color 400ms ease; }
  #scanner .scan-bar {
    transition: background-color 400ms ease; }
  #scanner .scan-box {
    transition: background-color 400ms ease; }
    #scanner .scan-box .corner-negative path {
      transition: fill 400ms ease, stroke 400ms ease; }
  #scanner .gray-box {
    transition: background-color 400ms ease; }
  #scanner .scanner-paused .scanner-page.page {
    background-color: #b2b2b2 !important;
    transition: background-color 400ms ease; }
  #scanner .scanner-paused .scan-box {
    transition: background-color 400ms ease;
    background-color: transparent;
    color: transparent; }
    #scanner .scanner-paused .scan-box > {
      background-color: transparent;
      color: transparent;
      display: none; }
    #scanner .scanner-paused .scan-box .corner-negative path {
      fill: transparent;
      stroke: transparent; }
    #scanner .scanner-paused .scan-box .corner path {
      fill: transparent;
      stroke: transparent; }
  #scanner .scanner-paused .gray-box {
    transition: background-color 400ms ease;
    background-color: transparent;
    color: transparent; }
  #scanner .scanner-page.page {
    background-color: transparent; }
  #scanner .navbar .button-link {
    color: #FFFFFF;
    border: none;
    padding: 0px; }
  #scanner .navbar .icon-back {
    margin-top: -3px;
    margin-right: 7px;
    vertical-align: middle; }
  #scanner .navbar-inner > div {
    min-width: 55px;
    margin-right: 0; }
  #scanner video#ScannerVideo {
    position: absolute;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    object-fit: cover; }
  #scanner p {
    text-align: center;
    color: white;
    width: 100%;
    height: 25px;
    padding-top: 0; }
  #scanner .page {
    transition: background 300ms ease;
    background: transparent; }
  #scanner .side-row {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 150px; }
  #scanner .scan-box .corner-negative path {
    fill: #0000004D; }
  #scanner .gray-box {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    background: #0000004D; }
    #scanner .gray-box.top {
      height: 38%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center; }
    #scanner .gray-box.bottom {
      height: calc(62% - 150px);
      width: 100%;
      z-index: 1; }
    #scanner .gray-box.side {
      height: 100%;
      width: calc((100vw - 150px) / 2); }
  #scanner .success.scan-box svg.corner path {
    stroke: white;
    stroke-width: 3px;
    transition: none;
    filter: blur(0.5px); }
  #scanner .success.scan-box .scan-bar {
    background-color: white;
    transition: none; }
  #scanner .scan-box {
    height: 100%;
    width: 150px;
    background: transparent;
    position: relative; }
    #scanner .scan-box svg.corner path {
      transition: stroke .15s ease-out, stroke-width .15s ease-out; }
    #scanner .scan-box .corner-negative.top-left {
      position: absolute;
      top: 0px;
      left: 0px;
      transform: rotate(0deg); }
    #scanner .scan-box .corner-negative.top-right {
      transform: rotate(90deg);
      position: absolute;
      top: 0px;
      right: 0px; }
    #scanner .scan-box .corner-negative.bottom-left {
      position: absolute;
      transform: rotate(-90deg);
      bottom: 0px;
      left: 0px; }
    #scanner .scan-box .corner-negative.bottom-right {
      position: absolute;
      bottom: 0px;
      right: 0px;
      transform: rotate(-180deg); }
    #scanner .scan-box .g-font-icon.blue {
      font-weight: 100;
      font-size: 30px; }
    #scanner .scan-box .corner.top-left {
      position: absolute;
      top: -8px;
      left: -8px;
      transform: rotate(0deg); }
    #scanner .scan-box .corner.top-right {
      transform: rotate(90deg);
      position: absolute;
      top: -8px;
      right: -8px; }
    #scanner .scan-box .corner.bottom-left {
      position: absolute;
      transform: rotate(-90deg);
      bottom: -8px;
      left: -8px; }
    #scanner .scan-box .corner.bottom-right {
      position: absolute;
      bottom: -8px;
      right: -8px;
      transform: rotate(-180deg); }
  #scanner .flex-row {
    display: flex;
    width: 100%;
    justify-content: center; }
  #scanner .bottom-buttons {
    position: absolute;
    bottom: 25px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    transition: bottom 300ms ease; }
    #scanner .bottom-buttons .g-button {
      width: 250px;
      margin: 0; }
  #scanner .manual-text {
    width: 100%;
    margin-bottom: 5px; }
  #scanner .pages {
    background: transparent;
    transition: background-color 300ms ease; }
  #scanner .empty .manual-add {
    background-color: transparent; }
  #scanner .empty .enter-helper {
    color: transparent; }
  #scanner .enter-helper {
    color: white;
    transition: background-color .15s ease;
    text-align: center;
    margin-top: 4px; }
  #scanner .manual-add {
    transition: background-color .15s ease;
    position: absolute;
    top: 0;
    right: 4px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    background-color: #2d4f82;
    height: 40px;
    width: 40px; }
    #scanner .manual-add .g-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto; }
  #scanner .empty-box-opposite-flashlight {
    height: 50px;
    width: 50px; }
  #scanner .flashlight-container {
    transition: transform .1s ease;
    background: #0000004D;
    height: 50px;
    display: flex;
    align-items: center;
    width: 50px;
    justify-content: center;
    border-radius: 25px;
    margin-right: 10px; }
    #scanner .flashlight-container:has(> .flashlight.pressed) {
      transform: scale(1.2); }
    #scanner .flashlight-container:has(> .flashlight-white.pressed) {
      transform: scale(1.2); }
    #scanner .flashlight-container.enabled {
      background: white; }
    #scanner .flashlight-container .flashlight {
      box-sizing: border-box;
      padding: 18px;
      -webkit-user-select: none;
      /* Safari */
      -ms-user-select: none;
      /* IE 10 and IE 11 */
      user-select: none;
      /* Standard syntax */ }
    #scanner .flashlight-container .flashlight-white {
      box-sizing: border-box;
      padding: 18px;
      -webkit-user-select: none;
      /* Safari */
      -ms-user-select: none;
      /* IE 10 and IE 11 */
      user-select: none;
      /* Standard syntax */ }

body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) {
  display: block;
  z-index: 13000;
  width: 100%;
  position: absolute;
  top: env(safe-area-inset-top);
  bottom: 0; }
  body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .g-scanner .scanner-page.page {
    background-color: #b2b2b2 !important;
    transition: background-color 400ms ease; }
  body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .g-scanner .scan-box {
    transition: background-color 400ms ease;
    background-color: transparent;
    color: transparent; }
    body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .g-scanner .scan-box > {
      background-color: transparent;
      color: transparent;
      display: none; }
    body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .g-scanner .scan-box .corner-negative path {
      fill: transparent;
      stroke: transparent; }
    body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .g-scanner .scan-box .corner path {
      fill: transparent;
      stroke: transparent; }
  body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .g-scanner .gray-box {
    transition: background-color 400ms ease;
    background-color: transparent;
    color: transparent; }
  body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .g-scanner .scanner-page.page {
    background-color: #FAFAFD !important; }
  body.transparent-incoming:not(.transparent-incoming-2) > #scanner:has(.animate-scanner-from-behind) .bottom-buttons {
    bottom: -300px; }

body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .scanner-page.page {
  background-color: #b2b2b2 !important;
  transition: background-color 400ms ease; }

body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .scan-box {
  transition: background-color 400ms ease;
  background-color: transparent;
  color: transparent; }
  body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .scan-box > {
    background-color: transparent;
    color: transparent;
    display: none; }
  body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .scan-box .corner-negative path {
    fill: transparent;
    stroke: transparent; }
  body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .scan-box .corner path {
    fill: transparent;
    stroke: transparent; }

body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .gray-box {
  transition: background-color 400ms ease;
  background-color: transparent;
  color: transparent; }

body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .scanner-page.page {
  background-color: #FAFAFD !important; }

body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .animate-from-top {
  top: -50%; }

body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner.animate-scanner-from-right .navbar-inner {
  left: 50%; }
  body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner.animate-scanner-from-right .navbar-inner * {
    filter: opacity(0) !important;
    background-color: transparent !important;
    color: transparent !important; }

body.transparent-incoming-2:not(.transparent) > #scanner .g-scanner .bottom-buttons {
  bottom: -300px; }

body.transparent > #scanner .animate-from-top {
  top: 0; }

body.transparent-incoming-2 > #scanner {
  display: block;
  z-index: 13000;
  width: 100%;
  position: absolute;
  top: env(safe-area-inset-top);
  bottom: 0; }

html.transparent {
  background-color: transparent; }

html.transparent-incoming-2 {
  background-color: transparent; }

@-webkit-keyframes upAndDown {
  0% {
    top: 0px; }
  50% {
    top: 130px; }
  100% {
    top: 0px; } }

@keyframes upAndDown {
  0% {
    top: 0px; }
  50% {
    top: 130px; }
  100% {
    top: 0px; } }
