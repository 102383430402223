.ios .g-login .list-block .item-inner:after {
  height: 0; }

.ios .g-login .g-input {
  width: 100%; }

.ios .g-login .forgot {
  text-align: right;
  color: white;
  font-weight: 600;
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
  margin-top: 20px; }

.ios .g-login .personal-device {
  display: flex;
  max-width: 315px;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; }
  .ios .g-login .personal-device p {
    margin: 0; }

.ios .g-login .g-check-box {
  min-width: 35px;
  margin-right: 15px; }

.ios .g-login .no-account {
  text-align: center;
  width: 100%;
  max-width: 100%; }

.ios .g-login strong {
  margin-left: 20px; }

.ios .g-login .navbar {
  background: transparent; }

.ios .g-login p {
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
  color: white; }
  .ios .g-login p a {
    color: white;
    margin-left: 3px;
    font-weight: 600; }

.ios .g-login .page-content {
  padding-top: 60px;
  padding-right: 30px;
  padding-left: 30px;
  background-image: linear-gradient(to top, #345c9b 0%, #2d4f82 100%); }
