.g-un-administer-multi-use-page .g-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px; }

.g-un-administer-multi-use-page .i-row {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-top: 10px;
  border-bottom: 1px solid #CFD5DE; }
  .g-un-administer-multi-use-page .i-row.location {
    border: none; }
  .g-un-administer-multi-use-page .i-row.un-administer {
    height: 50px; }
  .g-un-administer-multi-use-page .i-row:last-child {
    border-bottom: none; }

.g-un-administer-multi-use-page .input-label {
  margin-top: 20px; }

.g-un-administer-multi-use-page .g-input.item-input {
  border: 1px solid #CFD5DE; }

.g-un-administer-multi-use-page .g-label {
  color: #6d6d72; }

.g-un-administer-multi-use-page .label.g-label {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px; }

.g-un-administer-multi-use-page a.g-button.button {
  margin-top: 30px;
  margin-bottom: 30px; }
