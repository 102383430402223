.g-administer-details-page {
  background: white !important; }
  .g-administer-details-page .check-row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    align-items: center;
    padding-left: 15px; }
    .g-administer-details-page .check-row .g-label {
      margin-left: 20px; }
  .g-administer-details-page .page-content {
    padding-top: 44px; }
  .g-administer-details-page .g-input.hidden, .g-administer-details-page .g-drop-down.hidden {
    display: none; }
  .g-administer-details-page .g-input {
    border: 1px solid #CFD5DE; }
  .g-administer-details-page .g-content > div {
    margin-left: auto;
    margin-right: auto; }
  .g-administer-details-page .g-label {
    max-width: 315px;
    margin-bottom: 4px; }
  .g-administer-details-page .g-button:first-of-type {
    margin-top: 60px;
    margin-bottom: 20px; }
  .g-administer-details-page .g-drop-down {
    width: 100%; }
  .g-administer-details-page .all-codes {
    max-width: 315px; }
    .g-administer-details-page .all-codes .codes {
      padding-left: 10px;
      padding-top: 10px;
      color: #9c9c9c; }
