.ios #root .dialog-backdrop {
  z-index: 13002; }

.ios #root .dialog div {
  background: white;
  color: black; }

.ios #root .dialog .dialog-input {
  background: white;
  color: black;
  border: 1px solid #CFD5DE; }

.ios #root .dialog .dialog-buttons span {
  color: #4e92df; }
