.g-scanned-drugs {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  width: 100%;
  color: white;
  padding-right: 14px;
  position: relative;
  margin-top: 10px; }
  .g-scanned-drugs .drug-count {
    position: absolute;
    top: 0px;
    right: 24px;
    text-align: center;
    width: calc(30% - 5px);
    background: #ffffff21;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 4px; }
  .g-scanned-drugs ul::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */ }
  .g-scanned-drugs ul {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    height: 300px;
    width: 30%;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0px;
    z-index: 1;
    margin-top: 23px; }
    .g-scanned-drugs ul li {
      list-style-type: none;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0000004D;
      border-radius: 5px;
      text-align: center;
      overflow: hidden;
      transition: right .15s .15s ease, height .15s ease, margin-bottom .15s ease, background-color .15s .3s ease;
      position: relative;
      right: -109px;
      height: 0px;
      margin-bottom: 0;
      background: transparent; }
    .g-scanned-drugs ul li.animating-in {
      right: 10px;
      height: 17px;
      margin-bottom: 3px;
      background: #0000004D; }
