body #root .g-date-picker {
  position: relative;
  height: 50px;
  margin-bottom: 15px; }
  body #root .g-date-picker * {
    text-decoration: none !important; }
  body #root .g-date-picker input {
    background: white;
    height: 50px;
    margin-bottom: 15px;
    width: 315px;
    max-width: 315px;
    border-radius: 4px;
    padding: 18px;
    font-size: 14px; }
  body #root .g-date-picker .icon-clear-value {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 7px;
    bottom: 0;
    margin: auto;
    height: 26px; }
  body #root .g-date-picker input:focus {
    outline: none !important; }
  body #root .g-date-picker .react-datepicker-popper {
    z-index: 2; }
  body #root .g-date-picker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, body #root .g-date-picker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: white;
    display: none; }
  body #root .g-date-picker .react-datepicker__navigation {
    overflow: visible;
    border-color: transparent;
    outline: none; }

.g-date-picker::before {
  content: attr(data-placeholder);
  position: absolute;
  right: 35px;
  top: 15px;
  color: #747474;
  z-index: 1; }

.g-date-picker .react-datepicker__header {
  background-color: white;
  border-bottom: none; }

.g-date-picker .react-datepicker__navigation:after {
  content: '';
  position: absolute;
  display: block;
  height: 39px;
  left: -12px;
  width: 39px;
  background-repeat: no-repeat;
  top: -16px; }

.g-date-picker .react-datepicker__navigation--next {
  right: 20px; }

.g-date-picker .react-datepicker__day-name {
  color: #AAA; }

.g-date-picker .react-datepicker__day-name, .g-date-picker .react-datepicker__day, .g-date-picker .react-datepicker__time-name {
  width: 37px;
  height: 37px;
  line-height: 37px;
  border-radius: 20px !important;
  font-size: 17px;
  outline: none !important; }

.g-date-picker .react-datepicker__navigation--next:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style='pointer-events: none; display: block;'%3E%3Cg id='chevron-right'%3E%3Cpolygon fill='%234e92df' points='10,6 8.6,7.4 13.2,12 8.6,16.6 10,18 16,12 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E"); }

.g-date-picker .react-datepicker__navigation--previous:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style='pointer-events: none; display: block;'%3E%3Cg id='chevron-left'%3E%3Cpolygon fill='%234e92df' points='15.4,7.4 14,6 8,12 14,18 15.4,16.6 10.8,12 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E"); }

.g-date-picker .react-datepicker__day {
  background-color: #FFF;
  color: black;
  border-radius: 15px; }

.g-date-picker .react-datepicker__day--selected {
  background-color: #4e92df !important;
  color: white !important; }

.g-date-picker .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  background-color: #4e92df;
  color: white; }

.g-date-picker .react-datepicker__day--today {
  color: #4e92df !important;
  background-color: #4e92df44 !important; }

.g-date-picker .react-datepicker__day--outside-month {
  visibility: hidden; }
