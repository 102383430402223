.g-how-to-status .vial-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px; }

.g-how-to-status .title {
  color: white;
  margin-bottom: 10px; }

.g-how-to-status .content {
  width: 300px !important;
  box-sizing: border-box;
  position: relative;
  margin: auto; }

.g-how-to-status .g-content {
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 260px;
  max-width: 315px; }

.g-how-to-status .vial-fill-white, .g-how-to-status .vial-unfil-gray {
  height: 35px !important; }
