.g-box-check-confirm-page .ed-label.dark {
  margin-bottom: 20px; }

.g-box-check-confirm-page.page {
  padding-top: 44px;
  background: #eff0f4; }

.g-box-check-confirm-page .g-card .g-label.dark {
  margin-bottom: 20px; }

.g-box-check-confirm-page .g-card {
  margin-bottom: 30px; }

.g-box-check-confirm-page .g-label {
  color: #9c9c9c; }
