.ios a.g-button {
  padding: 13px;
  height: 50px;
  border: 0px;
  max-width: 315px;
  border-radius: 4px;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none !important;
  /* Safari */
  -ms-user-select: none !important;
  /* IE 10 and IE 11 */
  user-select: none !important;
  /* Standard syntax */ }
  .ios a.g-button.active-state {
    filter: brightness(0.9); }
  .ios a.g-button.no-shadow {
    box-shadow: none; }
  .ios a.g-button.border {
    border: 1px solid #CFD5DE; }
  .ios a.g-button.g-blue {
    color: #4e92df; }
  .ios a.g-button.g-darkblue {
    color: #2d4f82; }
  .ios a.g-button.g-white {
    color: white; }
  .ios a.g-button.g-gray {
    color: #e2e2e2; }
  .ios a.g-button.bg-white {
    background: white; }
  .ios a.g-button.bg-transparent {
    background: transparent; }
  .ios a.g-button.bg-blue {
    background: #4e92df; }
  .ios a.g-button.bg-darkblue {
    background: #2d4f82; }
  .ios a.g-button.center {
    position: relative;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .ios a.g-button.disabled {
    opacity: .5; }
