.g-drug-scanned {
  z-index: 13001; }
  .g-drug-scanned .g-drug-scanned-page {
    background: white !important; }
    .g-drug-scanned .g-drug-scanned-page .page-content {
      padding-top: 0 !important; }
    .g-drug-scanned .g-drug-scanned-page .edit-icon {
      height: 16px;
      padding: 10px; }
      .g-drug-scanned .g-drug-scanned-page .edit-icon path {
        color: #4e92df; }
    .g-drug-scanned .g-drug-scanned-page .date-picker-hidden-input {
      position: absolute !important;
      right: 0;
      margin: auto;
      width: 315px;
      z-index: 16; }
      .g-drug-scanned .g-drug-scanned-page .date-picker-hidden-input input {
        position: absolute;
        z-index: -1;
        top: 20px; }
  .g-drug-scanned .drug-options {
    margin-top: 30px; }
    .g-drug-scanned .drug-options .g-button {
      margin: auto;
      margin-bottom: 20px;
      margin-top: 20px; }
  .g-drug-scanned .content-block {
    margin-top: 0px; }
  .g-drug-scanned .g-navbar {
    background: #2d4f82;
    display: flex;
    color: white;
    justify-content: space-between;
    height: 46px;
    padding: 7px;
    box-sizing: border-box;
    text-align: center; }
    .g-drug-scanned .g-navbar div {
      margin: auto 0;
      min-width: 25px;
      min-height: 1px; }
  .g-drug-scanned .success {
    color: #2BAF45;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-top: 10px;
    height: 25px;
    margin-bottom: 5px; }
    .g-drug-scanned .success .g-font-icon.icon-done-all.g-sm {
      position: relative;
      top: -8px; }
    .g-drug-scanned .success div {
      min-width: 25px;
      height: 25px; }
    .g-drug-scanned .success p {
      margin: 0; }
  .g-drug-scanned .g-card {
    width: calc(100vw - 131px);
    padding-top: 0;
    padding-bottom: 0; }
    .g-drug-scanned .g-card .list {
      margin: 0; }
      .g-drug-scanned .g-card .list ul:before {
        display: none; }
      .g-drug-scanned .g-card .list ul:after {
        display: none; }
    .g-drug-scanned .g-card .item-content {
      height: 40px;
      padding-left: 0px; }
      .g-drug-scanned .g-card .item-content .item-inner {
        padding-right: 0 !important; }
    .g-drug-scanned .g-card .list-block {
      margin: 0;
      font-size: 14px; }
      .g-drug-scanned .g-card .list-block ul:before {
        height: 0px; }
      .g-drug-scanned .g-card .list-block ul:after {
        height: 0px; }
      .g-drug-scanned .g-card .list-block .item-inner {
        padding-bottom: 0; }
    .g-drug-scanned .g-card div > p:nth-of-type(2) {
      font-weight: 600;
      font-size: 14px; }
    .g-drug-scanned .g-card .item-inner > div {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center; }
    .g-drug-scanned .g-card div > .drug-type {
      text-transform: capitalize;
      text-align: right; }
    .g-drug-scanned .g-card div > p:nth-of-type(1) {
      font-weight: 300;
      font-size: 12px; }
  .g-drug-scanned .info {
    display: flex;
    justify-content: space-between; }
  .g-drug-scanned .vial {
    height: 175px;
    width: 97px; }
  .g-drug-scanned h2 {
    margin-bottom: 0; }
  .g-drug-scanned #ScanType.list-block {
    margin-top: 20px;
    margin-bottom: 20px; }
