.g-icon {
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */ }
  .g-icon.onClick {
    cursor: pointer; }
  .g-icon.pressed {
    filter: brightness(0.8); }
  .g-icon.disabled {
    opacity: 0.5; }
  .g-icon.access-alarms {
    background-image: url(access-alarms.svg); }
  .g-icon.access-time {
    background-image: url(access-time.svg); }
  .g-icon.accessibility {
    background-image: url(accessibility.svg); }
  .g-icon.account-box {
    background-image: url(account-box.svg); }
  .g-icon.account-circle {
    background-image: url(account-circle.svg); }
  .g-icon.adb {
    background-image: url(adb.svg); }
  .g-icon.add-alarm {
    background-image: url(add-alarm.svg); }
  .g-icon.add-box {
    background-image: url(add-box.svg); }
  .g-icon.corner {
    background-image: url(corner.svg); }
  .g-icon.flashlight {
    background-image: url(flashlight.svg); }
  .g-icon.flashlight-white {
    background-image: url(flashlight-white.svg); }
  .g-icon.data-matrix {
    height: 5px;
    width: 5px;
    background-image: url(data-matrix.svg); }
  .g-icon.add-circle-outline {
    background-image: url(add-circle-outline.svg); }
  .g-icon.add-circle {
    background-image: url(add-circle.svg); }
  .g-icon.add {
    background-image: url(add.svg); }
  .g-icon.add-white {
    background-image: url(add-white.svg); }
  .g-icon.airplanemode-off {
    background-image: url(airplanemode-off.svg); }
  .g-icon.airplanemode-on {
    background-image: url(airplanemode-on.svg); }
  .g-icon.android {
    background-image: url(android.svg); }
  .g-icon.apps {
    background-image: url(apps.svg); }
  .g-icon.archive {
    background-image: url(archive.svg); }
  .g-icon.arrow-back {
    background-image: url(arrow-back.svg); }
  .g-icon.arrow-drop-down-circle {
    background-image: url(arrow-drop-down-circle.svg); }
  .g-icon.arrow-drop-down {
    background-image: url(arrow-drop-down.svg); }
  .g-icon.arrow-drop-up {
    background-image: url(arrow-drop-up.svg); }
  .g-icon.arrow-forward {
    background-image: url(arrow-forward.svg); }
  .g-icon.attachment {
    background-image: url(attachment.svg); }
  .g-icon.auto-fix {
    background-image: url(auto-fix.svg); }
  .g-icon.backspace {
    background-image: url(backspace.svg); }
  .g-icon.backup {
    background-image: url(backup.svg); }
  .g-icon.beenhere {
    background-image: url(beenhere.svg); }
  .g-icon.biometric.g-xxl {
    height: 70px;
    width: 70px;
    margin-bottom: 10px; }
  .g-icon.biometric {
    background-image: url(biometric.svg); }
  .g-icon.block {
    background-image: url(block.svg); }
  .g-icon.bluetooth-audio {
    background-image: url(bluetooth-audio.svg); }
  .g-icon.bluetooth-connected {
    background-image: url(bluetooth-connected.svg); }
  .g-icon.bluetooth-disabled {
    background-image: url(bluetooth-disabled.svg); }
  .g-icon.bluetooth-searching {
    background-image: url(bluetooth-searching.svg); }
  .g-icon.bluetooth {
    background-image: url(bluetooth.svg); }
  .g-icon.book {
    background-image: url(book.svg); }
  .g-icon.bookmark-outline {
    background-image: url(bookmark-outline.svg); }
  .g-icon.bookmark {
    background-image: url(bookmark.svg); }
  .g-icon.bottle {
    background-image: url(bottle.svg); }
  .g-icon.brightness-auto {
    background-image: url(brightness-auto.svg); }
  .g-icon.brightness-high {
    background-image: url(brightness-high.svg); }
  .g-icon.brightness-low {
    background-image: url(brightness-low.svg); }
  .g-icon.brightness-medium {
    background-image: url(brightness-medium.svg); }
  .g-icon.bug-report {
    background-image: url(bug-report.svg); }
  .g-icon.cake {
    background-image: url(cake.svg); }
  .g-icon.calendar {
    background-image: url(calendar.svg); }
  .g-icon.calendar_inside {
    background-image: url(calendar_inside.svg); }
  .g-icon.call-end {
    background-image: url(call-end.svg); }
  .g-icon.call-made {
    background-image: url(call-made.svg); }
  .g-icon.call-merge {
    background-image: url(call-merge.svg); }
  .g-icon.call-missed {
    background-image: url(call-missed.svg); }
  .g-icon.call-received {
    background-image: url(call-received.svg); }
  .g-icon.call-split {
    background-image: url(call-split.svg); }
  .g-icon.call {
    background-image: url(call.svg); }
  .g-icon.camera-alt {
    background-image: url(camera-alt.svg); }
  .g-icon.camera-roll {
    background-image: url(camera-roll.svg); }
  .g-icon.camera {
    background-image: url(camera.svg); }
  .g-icon.cancel {
    background-image: url(cancel.svg); }
  .g-icon.cast-connected {
    background-image: url(cast-connected.svg); }
  .g-icon.cast {
    background-image: url(cast.svg); }
  .g-icon.chat {
    background-image: url(chat.svg); }
  .g-icon.check-box-blank {
    background-image: url(check-box-blank.svg); }
  .g-icon.check-box-checked {
    background-image: url(check-box-checked.svg); }
  .g-icon.check-box-outline-blank {
    background-image: url(check-box-outline-blank.svg); }
  .g-icon.check-box-outline {
    background-image: url(check-box-outline.svg); }
  .g-icon.check-box {
    background-image: url(check-box.svg); }
  .g-icon.check-white {
    background-image: url(check-white.svg); }
  .g-icon.check-circle-blank {
    background-image: url(check-circle-blank.svg); }
  .g-icon.check-circle-outline-blank {
    background-image: url(check-circle-outline-blank.svg); }
  .g-icon.check-circle-outline {
    background-image: url(check-circle-outline.svg); }
  .g-icon.check-circle {
    background-image: url(check-circle.svg); }
  .g-icon.check {
    background-image: url(check.svg); }
  .g-icon.chevron-left {
    background-image: url(chevron-left.svg); }
  .g-icon.chevron-right {
    background-image: url(chevron-right.svg); }
  .g-icon.chromecast {
    background-image: url(chromecast.svg); }
  .g-icon.circles-add {
    background-image: url(circles-add.svg); }
  .g-icon.circles-extended {
    background-image: url(circles-extended.svg); }
  .g-icon.circles {
    background-image: url(circles.svg); }
  .g-icon.clear {
    background-image: url(clear.svg); }
  .g-icon.close {
    background-image: url(close.svg); }
  .g-icon.closed-caption {
    background-image: url(closed-caption.svg); }
  .g-icon.cloud-circle {
    background-image: url(cloud-circle.svg); }
  .g-icon.cloud-done {
    background-image: url(cloud-done.svg); }
  .g-icon.cloud-download {
    background-image: url(cloud-download.svg); }
  .g-icon.cloud-off {
    background-image: url(cloud-off.svg); }
  .g-icon.cloud-queue {
    background-image: url(cloud-queue.svg); }
  .g-icon.cloud-upload {
    background-image: url(cloud-upload.svg); }
  .g-icon.cloud {
    background-image: url(cloud.svg); }
  .g-icon.comment {
    background-image: url(comment.svg); }
  .g-icon.communities {
    background-image: url(communities.svg); }
  .g-icon.content-copy {
    background-image: url(content-copy.svg); }
  .g-icon.content-cut {
    background-image: url(content-cut.svg); }
  .g-icon.content-paste {
    background-image: url(content-paste.svg); }
  .g-icon.create {
    background-image: url(create.svg); }
  .g-icon.credit-card {
    background-image: url(credit-card.svg); }
  .g-icon.crop-free {
    background-image: url(crop-free.svg); }
  .g-icon.crop-landscape {
    background-image: url(crop-landscape.svg); }
  .g-icon.crop-portrait {
    background-image: url(crop-portrait.svg); }
  .g-icon.crop-square {
    background-image: url(crop-square.svg); }
  .g-icon.crop {
    background-image: url(crop.svg); }
  .g-icon.data-usage {
    background-image: url(data-usage.svg); }
  .g-icon.delete {
    background-image: url(delete.svg); }
  .g-icon.developer-mode {
    background-image: url(developer-mode.svg); }
  .g-icon.dialpad {
    background-image: url(dialpad.svg); }
  .g-icon.directions-bike {
    background-image: url(directions-bike.svg); }
  .g-icon.directions-bus {
    background-image: url(directions-bus.svg); }
  .g-icon.directions-car {
    background-image: url(directions-car.svg); }
  .g-icon.directions-subway {
    background-image: url(directions-subway.svg); }
  .g-icon.directions-train {
    background-image: url(directions-train.svg); }
  .g-icon.directions-transit {
    background-image: url(directions-transit.svg); }
  .g-icon.directions {
    background-image: url(directions.svg); }
  .g-icon.directionswalk {
    background-image: url(directionswalk.svg); }
  .g-icon.disc-full {
    background-image: url(disc-full.svg); }
  .g-icon.do-not-disturb {
    background-image: url(do-not-disturb.svg); }
  .g-icon.dock {
    background-image: url(dock.svg); }
  .g-icon.domain {
    background-image: url(domain.svg); }
  .g-icon.done-all {
    background-image: url(done-all.svg); }
  .g-icon.done {
    background-image: url(done.svg); }
  .g-icon.drafts {
    background-image: url(drafts.svg); }
  .g-icon.drive-document {
    background-image: url(drive-document.svg); }
  .g-icon.drive-drawing {
    background-image: url(drive-drawing.svg); }
  .g-icon.drive-eta {
    background-image: url(drive-eta.svg); }
  .g-icon.drive-file {
    background-image: url(drive-file.svg); }
  .g-icon.drive-form {
    background-image: url(drive-form.svg); }
  .g-icon.drive-fusiontable {
    background-image: url(drive-fusiontable.svg); }
  .g-icon.drive-image {
    background-image: url(drive-image.svg); }
  .g-icon.drive-ms-excel {
    background-image: url(drive-ms-excel.svg); }
  .g-icon.drive-ms-powerpoint {
    background-image: url(drive-ms-powerpoint.svg); }
  .g-icon.drive-ms-word {
    background-image: url(drive-ms-word.svg); }
  .g-icon.drive-pdf {
    background-image: url(drive-pdf.svg); }
  .g-icon.drive-presentation {
    background-image: url(drive-presentation.svg); }
  .g-icon.drive-script {
    background-image: url(drive-script.svg); }
  .g-icon.drive-site {
    background-image: url(drive-site.svg); }
  .g-icon.drive-spreadsheet {
    background-image: url(drive-spreadsheet.svg); }
  .g-icon.drive-video {
    background-image: url(drive-video.svg); }
  .g-icon.drive {
    background-image: url(drive.svg); }
  .g-icon.earth {
    background-image: url(earth.svg); }
  .g-icon.email {
    background-image: url(email.svg); }
  .g-icon.error {
    background-image: url(error.svg); }
  .g-icon.event {
    background-image: url(event.svg); }
  .g-icon.exit-to-app {
    background-image: url(exit-to-app.svg); }
  .g-icon.expand-less {
    background-image: url(expand-less.svg); }
  .g-icon.expand-more {
    background-image: url(expand-more.svg); }
  .g-icon.explore {
    background-image: url(explore.svg); }
  .g-icon.extension {
    background-image: url(extension.svg); }
  .g-icon.fast-forward {
    background-image: url(fast-forward.svg); }
  .g-icon.fast-rewind {
    background-image: url(fast-rewind.svg); }
  .g-icon.favorite-outline {
    background-image: url(favorite-outline.svg); }
  .g-icon.favorite {
    background-image: url(favorite.svg); }
  .g-icon.file-download {
    background-image: url(file-download.svg); }
  .g-icon.file-upload {
    background-image: url(file-upload.svg); }
  .g-icon.filter {
    background-image: url(filter.svg); }
  .g-icon.flag {
    background-image: url(flag.svg); }
  .g-icon.flash-auto {
    background-image: url(flash-auto.svg); }
  .g-icon.flash-off {
    background-image: url(flash-off.svg); }
  .g-icon.flash-on {
    background-image: url(flash-on.svg); }
  .g-icon.flights {
    background-image: url(flights.svg); }
  .g-icon.flip-to-back {
    background-image: url(flip-to-back.svg); }
  .g-icon.flip-to-front {
    background-image: url(flip-to-front.svg); }
  .g-icon.folder-mydrive {
    background-image: url(folder-mydrive.svg); }
  .g-icon.folder-shared {
    background-image: url(folder-shared.svg); }
  .g-icon.folder {
    background-image: url(folder.svg); }
  .g-icon.forum {
    background-image: url(forum.svg); }
  .g-icon.forward {
    background-image: url(forward.svg); }
  .g-icon.fullscreen-exit {
    background-image: url(fullscreen-exit.svg); }
  .g-icon.fullscreen {
    background-image: url(fullscreen.svg); }
  .g-icon.gamepad {
    background-image: url(gamepad.svg); }
  .g-icon.games {
    background-image: url(games.svg); }
  .g-icon.gesture {
    background-image: url(gesture.svg); }
  .g-icon.glass {
    background-image: url(glass.svg); }
  .g-icon.gmail {
    background-image: url(gmail.svg); }
  .g-icon.google-plus {
    background-image: url(google-plus.svg); }
  .g-icon.google {
    background-image: url(google.svg); }
  .g-icon.gps-fixed {
    background-image: url(gps-fixed.svg); }
  .g-icon.gps-not-fixed {
    background-image: url(gps-not-fixed.svg); }
  .g-icon.gps-off {
    background-image: url(gps-off.svg); }
  .g-icon.group-add {
    background-image: url(group-add.svg); }
  .g-icon.group {
    background-image: url(group.svg); }
  .g-icon.hangout-video-off {
    background-image: url(hangout-video-off.svg); }
  .g-icon.hangout-video {
    background-image: url(hangout-video.svg); }
  .g-icon.hangout {
    background-image: url(hangout.svg); }
  .g-icon.headset-mic {
    background-image: url(headset-mic.svg); }
  .g-icon.headset {
    background-image: url(headset.svg); }
  .g-icon.help {
    background-image: url(help.svg); }
  .g-icon.high-quality {
    background-image: url(high-quality.svg); }
  .g-icon.history {
    background-image: url(history.svg); }
  .g-icon.home {
    background-image: url(home.svg); }
  .g-icon.hotel {
    background-image: url(hotel.svg); }
  .g-icon.https {
    background-image: url(https.svg); }
  .g-icon.image {
    background-image: url(image.svg); }
  .g-icon.import-export {
    background-image: url(import-export.svg); }
  .g-icon.inbox {
    background-image: url(inbox.svg); }
  .g-icon.info-outline {
    background-image: url(info-outline.svg); }
  .g-icon.info {
    background-image: url(info.svg); }
  .g-icon.invert-colors {
    background-image: url(invert-colors.svg); }
  .g-icon.keep {
    background-image: url(keep.svg); }
  .g-icon.keep_track {
    background-image: url(keep_track.svg); }
  .g-icon.keyboard-alt {
    background-image: url(keyboard-alt.svg); }
  .g-icon.keyboard-arrow-down-blue {
    background-image: url(keyboard-arrow-down-blue.svg); }
  .g-icon.keyboard-arrow-down {
    background-image: url(keyboard-arrow-down.svg); }
  .g-icon.keyboard-arrow-left {
    background-image: url(keyboard-arrow-left.svg); }
  .g-icon.keyboard-arrow-right {
    background-image: url(keyboard-arrow-right.svg); }
  .g-icon.keyboard-arrow-up {
    background-image: url(keyboard-arrow-up.svg); }
  .g-icon.keyboard-backspace {
    background-image: url(keyboard-backspace.svg); }
  .g-icon.keyboard-capslock {
    background-image: url(keyboard-capslock.svg); }
  .g-icon.keyboard-control {
    background-image: url(keyboard-control.svg); }
  .g-icon.keyboard-hide {
    background-image: url(keyboard-hide.svg); }
  .g-icon.keyboard-return {
    background-image: url(keyboard-return.svg); }
  .g-icon.keyboard-tab {
    background-image: url(keyboard-tab.svg); }
  .g-icon.keyboard-voice {
    background-image: url(keyboard-voice.svg); }
  .g-icon.keyboard {
    background-image: url(keyboard.svg); }
  .g-icon.label-outline {
    background-image: url(label-outline.svg); }
  .g-icon.label {
    background-image: url(label.svg); }
  .g-icon.landscape {
    background-image: url(landscape.svg); }
  .g-icon.language {
    background-image: url(language.svg); }
  .g-icon.laptop {
    background-image: url(laptop.svg); }
  .g-icon.launch {
    background-image: url(launch.svg); }
  .g-icon.link {
    background-image: url(link.svg); }
  .g-icon.list {
    background-image: url(list.svg); }
  .g-icon.live-help {
    background-image: url(live-help.svg); }
  .g-icon.location-city {
    background-image: url(location-city.svg); }
  .g-icon.location-disabled {
    background-image: url(location-disabled.svg); }
  .g-icon.location-searching {
    background-image: url(location-searching.svg); }
  .g-icon.location {
    background-image: url(location.svg); }
  .g-icon.lock-open {
    background-image: url(lock-open.svg); }
  .g-icon.lock-outline {
    background-image: url(lock-outline.svg); }
  .g-icon.lock-tutorial {
    background-image: url(lock-tutorial.svg); }
  .g-icon.lock {
    background-image: url(lock.svg); }
  .g-icon.loop {
    background-image: url(loop.svg); }
  .g-icon.mail {
    background-image: url(mail.svg); }
  .g-icon.map {
    background-image: url(map.svg); }
  .g-icon.markunread {
    background-image: url(markunread.svg); }
  .g-icon.memory {
    background-image: url(memory.svg); }
  .g-icon.menu {
    background-image: url(menu.svg); }
  .g-icon.message {
    background-image: url(message.svg); }
  .g-icon.messenger {
    background-image: url(messenger.svg); }
  .g-icon.mic-none {
    background-image: url(mic-none.svg); }
  .g-icon.mic-off {
    background-image: url(mic-off.svg); }
  .g-icon.mic {
    background-image: url(mic.svg); }
  .g-icon.mms {
    background-image: url(mms.svg); }
  .g-icon.mood {
    background-image: url(mood.svg); }
  .g-icon.more-horiz {
    background-image: url(more-horiz.svg); }
  .g-icon.more-vert {
    background-image: url(more-vert.svg); }
  .g-icon.more {
    background-image: url(more.svg); }
  .g-icon.mouse {
    background-image: url(mouse.svg); }
  .g-icon.movie {
    background-image: url(movie.svg); }
  .g-icon.nest-protect {
    background-image: url(nest-protect.svg); }
  .g-icon.nest-thermostat {
    background-image: url(nest-thermostat.svg); }
  .g-icon.network-cell {
    background-image: url(network-cell.svg); }
  .g-icon.network-wifi {
    background-image: url(network-wifi.svg); }
  .g-icon.news {
    background-image: url(news.svg); }
  .g-icon.nfc {
    background-image: url(nfc.svg); }
  .g-icon.notifications-none {
    background-image: url(notifications-none.svg); }
  .g-icon.notifications-off {
    background-image: url(notifications-off.svg); }
  .g-icon.notifications-on {
    background-image: url(notifications-on.svg); }
  .g-icon.notifications-paused {
    background-image: url(notifications-paused.svg); }
  .g-icon.notifications {
    background-image: url(notifications.svg); }
  .g-icon.onb-phone {
    background-image: url(onb-phone.svg); }
  .g-icon.pages {
    background-image: url(pages.svg); }
  .g-icon.palette {
    background-image: url(palette.svg); }
  .g-icon.panorama {
    background-image: url(panorama.svg); }
  .g-icon.party-mode {
    background-image: url(party-mode.svg); }
  .g-icon.pause-circle-fill {
    background-image: url(pause-circle-fill.svg); }
  .g-icon.pause-circle-outline {
    background-image: url(pause-circle-outline.svg); }
  .g-icon.pause {
    background-image: url(pause.svg); }
  .g-icon.people {
    background-image: url(people.svg); }
  .g-icon.person-add {
    background-image: url(person-add.svg); }
  .g-icon.person-location {
    background-image: url(person-location.svg); }
  .g-icon.person-outline {
    background-image: url(person-outline.svg); }
  .g-icon.person {
    background-image: url(person.svg); }
  .g-icon.phone-bluetooth-speaker {
    background-image: url(phone-bluetooth-speaker.svg); }
  .g-icon.phone-forwarded {
    background-image: url(phone-forwarded.svg); }
  .g-icon.phone-in-talk {
    background-image: url(phone-in-talk.svg); }
  .g-icon.phone-locked {
    background-image: url(phone-locked.svg); }
  .g-icon.phone-missed {
    background-image: url(phone-missed.svg); }
  .g-icon.phone-paused {
    background-image: url(phone-paused.svg); }
  .g-icon.phone {
    background-image: url(phone.svg); }
  .g-icon.photo-album {
    background-image: url(photo-album.svg); }
  .g-icon.photo-library {
    background-image: url(photo-library.svg); }
  .g-icon.photo {
    background-image: url(photo.svg); }
  .g-icon.place {
    background-image: url(place.svg); }
  .g-icon.play-arrow {
    background-image: url(play-arrow.svg); }
  .g-icon.play-circle-fill {
    background-image: url(play-circle-fill.svg); }
  .g-icon.play-circle-outline {
    background-image: url(play-circle-outline.svg); }
  .g-icon.play-download {
    background-image: url(play-download.svg); }
  .g-icon.play-install {
    background-image: url(play-install.svg); }
  .g-icon.plus-one {
    background-image: url(plus-one.svg); }
  .g-icon.poll {
    background-image: url(poll.svg); }
  .g-icon.polymer {
    background-image: url(polymer.svg); }
  .g-icon.portrait {
    background-image: url(portrait.svg); }
  .g-icon.post-blogger {
    background-image: url(post-blogger.svg); }
  .g-icon.post-facebook {
    background-image: url(post-facebook.svg); }
  .g-icon.post-gplus {
    background-image: url(post-gplus.svg); }
  .g-icon.post-instagram {
    background-image: url(post-instagram.svg); }
  .g-icon.post-linkedin {
    background-image: url(post-linkedin.svg); }
  .g-icon.post-pinterest {
    background-image: url(post-pinterest.svg); }
  .g-icon.post-tumblr {
    background-image: url(post-tumblr.svg); }
  .g-icon.post-twitter {
    background-image: url(post-twitter.svg); }
  .g-icon.print {
    background-image: url(print.svg); }
  .g-icon.public {
    background-image: url(public.svg); }
  .g-icon.queue {
    background-image: url(queue.svg); }
  .g-icon.radio-button-off {
    background-image: url(radio-button-off.svg); }
  .g-icon.radio-button-on {
    background-image: url(radio-button-on.svg); }
  .g-icon.receipt {
    background-image: url(receipt.svg); }
  .g-icon.refresh {
    background-image: url(refresh.svg); }
  .g-icon.reminder {
    background-image: url(reminder.svg); }
  .g-icon.remove-circle-outline {
    background-image: url(remove-circle-outline.svg); }
  .g-icon.remove-circle {
    background-image: url(remove-circle.svg); }
  .g-icon.remove {
    background-image: url(remove.svg); }
  .g-icon.replay {
    background-image: url(replay.svg); }
  .g-icon.reply-all {
    background-image: url(reply-all.svg); }
  .g-icon.reply {
    background-image: url(reply.svg); }
  .g-icon.report {
    background-image: url(report.svg); }
  .g-icon.ring-volume {
    background-image: url(ring-volume.svg); }
  .g-icon.rotate-left {
    background-image: url(rotate-left.svg); }
  .g-icon.rotate-right {
    background-image: url(rotate-right.svg); }
  .g-icon.satellite {
    background-image: url(satellite.svg); }
  .g-icon.save {
    background-image: url(save.svg); }
  .g-icon.schedule {
    background-image: url(schedule.svg); }
  .g-icon.school {
    background-image: url(school.svg); }
  .g-icon.screen-lock-landscape {
    background-image: url(screen-lock-landscape.svg); }
  .g-icon.screen-lock-portrait {
    background-image: url(screen-lock-portrait.svg); }
  .g-icon.screen-lock-rotation {
    background-image: url(screen-lock-rotation.svg); }
  .g-icon.screen-rotation {
    background-image: url(screen-rotation.svg); }
  .g-icon.sd-card {
    background-image: url(sd-card.svg); }
  .g-icon.sd-storage {
    background-image: url(sd-storage.svg); }
  .g-icon.search {
    background-image: url(search.svg); }
  .g-icon.select-all {
    background-image: url(select-all.svg); }
  .g-icon.send {
    background-image: url(send.svg); }
  .g-icon.settings-applications {
    background-image: url(settings-applications.svg); }
  .g-icon.settings-bluetooth {
    background-image: url(settings-bluetooth.svg); }
  .g-icon.settings-cell {
    background-image: url(settings-cell.svg); }
  .g-icon.settings-phone {
    background-image: url(settings-phone.svg); }
  .g-icon.settings-power {
    background-image: url(settings-power.svg); }
  .g-icon.settings-voice {
    background-image: url(settings-voice.svg); }
  .g-icon.settings {
    background-image: url(settings.svg); }
  .g-icon.share-alt {
    background-image: url(share-alt.svg); }
  .g-icon.share {
    background-image: url(share.svg); }
  .g-icon.shopping-basket {
    background-image: url(shopping-basket.svg); }
  .g-icon.shopping-cart {
    background-image: url(shopping-cart.svg); }
  .g-icon.shuffle {
    background-image: url(shuffle.svg); }
  .g-icon.signal-cellular-1-bar {
    background-image: url(signal-cellular-1-bar.svg); }
  .g-icon.signal-cellular-2-bar {
    background-image: url(signal-cellular-2-bar.svg); }
  .g-icon.signal-cellular-3-bar {
    background-image: url(signal-cellular-3-bar.svg); }
  .g-icon.signal-cellular-4-bar {
    background-image: url(signal-cellular-4-bar.svg); }
  .g-icon.signal-wifi-1-bar {
    background-image: url(signal-wifi-1-bar.svg); }
  .g-icon.signal-wifi-2-bar {
    background-image: url(signal-wifi-2-bar.svg); }
  .g-icon.signal-wifi-3-bar {
    background-image: url(signal-wifi-3-bar.svg); }
  .g-icon.signal-wifi-4-bar {
    background-image: url(signal-wifi-4-bar.svg); }
  .g-icon.sim-card-alert {
    background-image: url(sim-card-alert.svg); }
  .g-icon.skip-next {
    background-image: url(skip-next.svg); }
  .g-icon.skip-previous {
    background-image: url(skip-previous.svg); }
  .g-icon.slideshow {
    background-image: url(slideshow.svg); }
  .g-icon.sms-failed {
    background-image: url(sms-failed.svg); }
  .g-icon.sms {
    background-image: url(sms.svg); }
  .g-icon.sort {
    background-image: url(sort.svg); }
  .g-icon.speaker {
    background-image: url(speaker.svg); }
  .g-icon.star-half {
    background-image: url(star-half.svg); }
  .g-icon.star-outline {
    background-image: url(star-outline.svg); }
  .g-icon.star-rate {
    background-image: url(star-rate.svg); }
  .g-icon.star {
    background-image: url(star.svg); }
  .g-icon.stop {
    background-image: url(stop.svg); }
  .g-icon.storage {
    background-image: url(storage.svg); }
  .g-icon.store {
    background-image: url(store.svg); }
  .g-icon.swap-driving-apps {
    background-image: url(swap-driving-apps.svg); }
  .g-icon.swap-horiz {
    background-image: url(swap-horiz.svg); }
  .g-icon.swap-vert-circle {
    background-image: url(swap-vert-circle.svg); }
  .g-icon.swap-vert {
    background-image: url(swap-vert.svg); }
  .g-icon.switch-camera {
    background-image: url(switch-camera.svg); }
  .g-icon.switch-video {
    background-image: url(switch-video.svg); }
  .g-icon.sync-disabled {
    background-image: url(sync-disabled.svg); }
  .g-icon.sync-green {
    background-image: url(sync-green.svg); }
  .g-icon.sync-problem-red {
    background-image: url(sync-problem-red.svg); }
  .g-icon.sync-problem {
    background-image: url(sync-problem.svg); }
  .g-icon.sync {
    background-image: url(sync.svg); }
  .g-icon.system-update {
    background-image: url(system-update.svg); }
  .g-icon.tab-unselected {
    background-image: url(tab-unselected.svg); }
  .g-icon.tab {
    background-image: url(tab.svg); }
  .g-icon.tablet {
    background-image: url(tablet.svg); }
  .g-icon.tag-faces {
    background-image: url(tag-faces.svg); }
  .g-icon.tap-and-play {
    background-image: url(tap-and-play.svg); }
  .g-icon.terrain {
    background-image: url(terrain.svg); }
  .g-icon.text-format {
    background-image: url(text-format.svg); }
  .g-icon.textsms {
    background-image: url(textsms.svg); }
  .g-icon.theaters {
    background-image: url(theaters.svg); }
  .g-icon.thumb-down {
    background-image: url(thumb-down.svg); }
  .g-icon.thumb-up {
    background-image: url(thumb-up.svg); }
  .g-icon.time-to-leave {
    background-image: url(time-to-leave.svg); }
  .g-icon.timelapse {
    background-image: url(timelapse.svg); }
  .g-icon.timer {
    background-image: url(timer.svg); }
  .g-icon.today {
    background-image: url(today.svg); }
  .g-icon.traffic {
    background-image: url(traffic.svg); }
  .g-icon.translate {
    background-image: url(translate.svg); }
  .g-icon.tv {
    background-image: url(tv.svg); }
  .g-icon.undo {
    background-image: url(undo.svg); }
  .g-icon.unfold-less {
    background-image: url(unfold-less.svg); }
  .g-icon.unfold-more {
    background-image: url(unfold-more.svg); }
  .g-icon.unknown-1 {
    background-image: url(unknown-1.svg); }
  .g-icon.unknown-2 {
    background-image: url(unknown-2.svg); }
  .g-icon.unknown-3 {
    background-image: url(unknown-3.svg); }
  .g-icon.unknown-4 {
    background-image: url(unknown-4.svg); }
  .g-icon.unknown-5 {
    background-image: url(unknown-5.svg); }
  .g-icon.unknown-6 {
    background-image: url(unknown-6.svg); }
  .g-icon.unknown-7 {
    background-image: url(unknown-7.svg); }
  .g-icon.usb {
    background-image: url(usb.svg); }
  .g-icon.vial-fill-white {
    background-image: url(vial-fill-white.svg); }
  .g-icon.vial-fill {
    background-image: url(vial-fill.svg); }
  .g-icon.ndc-vial-barcode {
    background-image: url(ndc-vial-barcode.svg); }
  .g-icon.vial-plus {
    background-image: url(vial-plus.svg); }
  .g-icon.vial-unfil-gray {
    background-image: url(vial-unfil-gray.svg); }
  .g-icon.vial-unfill {
    background-image: url(vial-unfill.svg); }
  .g-icon.vibration {
    background-image: url(vibration.svg); }
  .g-icon.video-youtube {
    background-image: url(video-youtube.svg); }
  .g-icon.videocam-off {
    background-image: url(videocam-off.svg); }
  .g-icon.videocam {
    background-image: url(videocam.svg); }
  .g-icon.view-array {
    background-image: url(view-array.svg); }
  .g-icon.view-column {
    background-image: url(view-column.svg); }
  .g-icon.view-headline {
    background-image: url(view-headline.svg); }
  .g-icon.view-list {
    background-image: url(view-list.svg); }
  .g-icon.view-module {
    background-image: url(view-module.svg); }
  .g-icon.view-quilt {
    background-image: url(view-quilt.svg); }
  .g-icon.view-stream {
    background-image: url(view-stream.svg); }
  .g-icon.visibility-off {
    background-image: url(visibility-off.svg); }
  .g-icon.visibility {
    background-image: url(visibility.svg); }
  .g-icon.voice {
    background-image: url(voice.svg); }
  .g-icon.voicemail {
    background-image: url(voicemail.svg); }
  .g-icon.volume-down {
    background-image: url(volume-down.svg); }
  .g-icon.volume-mute {
    background-image: url(volume-mute.svg); }
  .g-icon.volume-off {
    background-image: url(volume-off.svg); }
  .g-icon.volume-up {
    background-image: url(volume-up.svg); }
  .g-icon.vpn {
    background-image: url(vpn.svg); }
  .g-icon.warning {
    background-image: url(warning.svg); }
  .g-icon.watch {
    background-image: url(watch.svg); }
  .g-icon.wb-auto {
    background-image: url(wb-auto.svg); }
  .g-icon.wb-cloudy {
    background-image: url(wb-cloudy.svg); }
  .g-icon.wb-incandescent {
    background-image: url(wb-incandescent.svg); }
  .g-icon.wb-irradescent {
    background-image: url(wb-irradescent.svg); }
  .g-icon.wb-sunny {
    background-image: url(wb-sunny.svg); }
  .g-icon.web {
    background-image: url(web.svg); }
  .g-icon.whatshot {
    background-image: url(whatshot.svg); }
  .g-icon.wifi-tethering {
    background-image: url(wifi-tethering.svg); }
  .g-icon.work {
    background-image: url(work.svg); }
  .g-icon.g-xxxxl {
    height: 100px;
    width: 100px; }
  .g-icon.g-xxxl {
    height: 80px;
    width: 80px; }
  .g-icon.g-xxl {
    height: 60px;
    width: 60px; }
  .g-icon.g-xl {
    height: 50px;
    width: 50px; }
  .g-icon.g-lg {
    height: 40px;
    width: 40px; }
  .g-icon.g-md {
    height: 30px;
    width: 30px; }
  .g-icon.g-sm {
    height: 25px;
    width: 25px; }
  .g-icon.g-xsm {
    height: 18px;
    width: 18px; }
  .g-icon.g-xxsm {
    height: 10px;
    width: 10px; }
  .g-icon.g-disabled {
    cursor: not-allowed; }
