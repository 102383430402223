.g-input {
  position: relative;
  box-sizing: border-box;
  height: 50px;
  max-width: 315px;
  margin-bottom: 15px;
  border-radius: 4px;
  overflow: hidden; }
  .g-input.center {
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }
  .g-input.border {
    border: 1px solid #CFD5DE; }
  .g-input input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    background: 0 0;
    box-shadow: none;
    display: block;
    margin: 0;
    color: #222;
    font-family: inherit;
    background: white;
    box-sizing: border-box;
    padding: 18px;
    font-size: 14px;
    border: 0;
    border-radius: 4px;
    height: 100%;
    width: 100%; }
    .g-input input.icon {
      padding-left: 40px; }
  .g-input .g-hide-show-password {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
    padding-top: 16px;
    padding-left: 9px;
    padding-right: 6px; }
    .g-input .g-hide-show-password svg path {
      fill: #444; }
  .g-input .g-x-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6px;
    padding: 9px;
    height: 12px;
    margin: auto; }
    .g-input .g-x-icon path {
      stroke: #444; }
  .g-input input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa; }
  .g-input input[type="date"]:focus:before,
  .g-input input[type="date"]:valid:before {
    content: ""; }
  .g-input .g-font-icon {
    position: absolute;
    top: 14px;
    left: 13px;
    font-size: 20px; }
