.g-signup .g-button {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto; }

.g-signup .g-input {
  margin-left: auto;
  margin-right: auto; }

.g-signup .navbar {
  margin-bottom: 30px;
  background: transparent; }
  .g-signup .navbar:after {
    height: 0; }

.g-signup p {
  color: white;
  text-align: center;
  margin-top: 40px; }
  .g-signup p a {
    color: white;
    margin-left: 3px;
    font-weight: 600; }

.g-signup .page-content {
  padding-top: 60px;
  padding-right: 30px;
  padding-left: 30px;
  background-image: linear-gradient(to top, #345c9b 0%, #2d4f82 100%); }
