.g-image-input input {
  display: none; }

.g-image-input img {
  height: 100px;
  width: 100%;
  object-fit: contain; }

.g-image-input:has(> .camera.active-state) {
  transform: scale(1.2); }

.g-image-input:has(> .camera-white.active-state) {
  transform: scale(1.2); }

.g-image-input:not(.uploading) label {
  background: #0000004D; }

.g-image-input label {
  transition: transform .1s ease;
  background: transparent;
  height: 50px;
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
  border-radius: 25px; }

.g-image-input.enabled {
  background: white; }

.g-image-input.invalid label {
  border: 1px solid #ff0000; }

.g-image-input .camera {
  box-sizing: border-box;
  padding: 18px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */ }

.g-image-input .spinning-loading {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid white;
  border-top-color: #747474;
  border-right-color: #747474;
  animation: spinning-loading 0.7s infinite forwards; }

@keyframes spinning-loading {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
