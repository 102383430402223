.g-how-to-daily-checks .rectangle-box {
  border-radius: 5px;
  height: 324px;
  width: 300px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 20px;
  text-align: center;
  color: white;
  padding: 20px;
  box-sizing: border-box; }

.g-how-to-daily-checks .content {
  position: relative; }

.g-how-to-daily-checks .calendar_inside.g-icon {
  height: 200px;
  width: 200px;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
  margin: auto; }

.g-how-to-daily-checks .days-of-the-week {
  margin-top: 20px;
  display: flex;
  justify-content: space-around; }
