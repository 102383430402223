.g-toast {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999999;
  background: #ffffffE0;
  max-width: 310px;
  max-height: 100px;
  padding: 12px;
  border-radius: 4px;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: flex-start;
  top: -100px;
  transition: top 400ms ease; }
  .g-toast svg {
    margin-right: 12px;
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px; }
  .g-toast.success {
    border-left: 6px solid #2BAF45; }
    .g-toast.success svg circle {
      fill: #2BAF45; }
  .g-toast.error {
    border-left: 6px solid #ff0000; }
    .g-toast.error svg circle {
      fill: red; }
  .g-toast.show {
    top: calc(10px + env(safe-area-inset-top)); }
