.g-box-check-scan-progress-card {
  padding: 12px;
  width: calc(100vw - 50px);
  max-width: 380px;
  margin: auto;
  border-radius: 30px;
  background: #ffffffc2;
  position: relative;
  box-sizing: border-box;
  border: none; }
  .g-box-check-scan-progress-card .g-button {
    border-radius: 30px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 100% !important;
    width: 100% !important; }
  .g-box-check-scan-progress-card .g-label.medium {
    margin-bottom: 12px; }
  .g-box-check-scan-progress-card > * {
    z-index: 1;
    position: relative; }
