.g-how-to-keep-track .keep_track {
  font-size: 290px;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 290px;
  width: 290px;
  color: white; }
